import React, { useEffect, useState } from 'react';
import { CANCELLATION_OR_REVOCATION_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import { isMobileOnly } from 'react-device-detect';
import {
  generateDays,
  generateYears,
  generateMonths,
} from '../../../constants/Flow';
import moment from 'moment';
import { getToken } from '../../../config/token';
import { Request } from '../../../config/requests';
import { showToast } from '../../../config/helpers';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import { CircularProgress } from '@mui/material';


const CancellationOrRevocation = ({ setIsLoading, isLoading, handleClose, setIsDone }) => {
  const { t } = useTranslation();
  const token = getToken();
  const [selectedItem, setSelectedItem] = useState(null);
  const [date, setDate] = useState({ year: null, month: null, day: null });
  const [newEmail, setNewEmail] = useState(null);
  const [petName, setPetName] = useState(null);
  const [prevPolicyNo, setPrevPolicyNo] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [isClaimExp, setIsClaimExp] = useState(null);
  const [policy, setPolicy] = useState({});
  const [selectedPet, setSelectedPet] = useState(null);
  const [terminationDate, setTerminationDate] = useState(0.0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [extProcess, setExtProcess] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(
    moment().add(10, 'year').format('YYYY')
  );
  const [datesValidationError, setDatesValidationError] = useState({
    status: false,
    message: '',
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [chipError, setChipError] = useState(false);
  const [tariff, setTariff] = useState(null);
  const [premiumAmount, setPremiumAmount] = React.useState(0.0);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = React.useState(null);
  const [payMonthFrequency, setPayMonthFrequency] = useState(null);
  const [claimsNo, setClaimsNo] = useState('');
  const [claimsStatus, setClaimsStatus] = useState(true);
  const [isClaimStatusLoading, setClaimLoading] = useState(false);
  const [petChip, setPetChip] = useState('');
  const [years, setYears] = useState({
    label: moment().format('yy'),
    value: moment().format('yy'),
  });
  const [months, setMonths] = useState({
    label: moment().format('MM'),
    value: moment().format('MM'),
  });
  const [days, setDays] = useState({
    label: moment().format('DD'),
    value: moment().format('DD'),
  });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const getTerminationDate = React.useMemo(() => {
    switch (selectedItem?.value) {
      case 'revocation':
        // return moment(
        //   `${days.value}.${months.value}.${years.value}`,
        //   'DD-MM-YYYY'
        // )
        //   .add(8, 'weeks')
        //   .add(3, 'days')
        //   .format('DD.MM.YYYY');
        // return `${days.value}.${months.value}.${years.value}`;

        return policy.dateInception.split('-').reverse().join('.');
      case 'ordinary_cancelation':
        if (!isYearPassed(policy.dateInception)) {
          return policy.dateExpiry.split('-').reverse().join('.');
        } else {
          let date = calculate30DaysTerminationDate(
            `${years.value}.${months.value}.${days.value}`
          );
          return date.split('-').reverse().join('.');
        }
      case 'cancellation_for_other_reasons':
        return `${days.value}.${months.value}.${years.value}`;
      case 'termination_claim_insurer':
        return `${days.value}.${months.value}.${years.value}`;
      case 'termination_claim_customer':
        return `${days.value}.${months.value}.${years.value}`;
      case 'ordinary_cancelation_insurer':
        console.log("THIS IS FUNCTIONNING")
        console.log(policy.dateExpiry,)
        if(isWithin90DayPeriod()){
          console.log("this date is within the 90 days period")
          return moment(policy.dateExpiry).add(1,'year').format("DD.MM.YYYY");
        }else{
          console.log("this date is not within the 90 days period")
          return policy.dateExpiry.split('-').reverse().join('.');
        }
        // return calculatePeriod().split('-').reverse().join('.');
        // return policy.dateExpiry.split('-').reverse().join('.');
      case 'withdrawal_by_the_insurer':
        return `${days.value}.${months.value}.${years.value}`;

      default:
        break;
    }
  }, [selectedItem, years, months, days]);


  const findContract = async () => {
    setIsLoadingData(true);
    let mainUrl = `api/policy/${policyNo}`;
    let res = await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    });
    res = await res.json();
    setPolicy(res);
    setPrevPolicyNo(policyNo)
    findPet(res.policy_id);
  };


  const findPet = async (policyId) => {
    let mainUrl = `api/users/v2/pet?policy_id=${policyId}`;
    await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectedPet(data.data);
        setTariff(data.products.petInsurance.data.selectedPackage);
        setSelectedCoverage(data.products.petInsurance.data.selectedCoverage);
        setPayMonthFrequency(data.quote.schedules.payMonthFrequency);

        calculatePremiumAmount(
          policyId,
          data.products.petInsurance.data.selectedPackage,
          data.products.petInsurance.data.selectedCoverage,
          data.quote.schedules.payMonthFrequency
        );
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  };


  function isWithin45Days(date1, date2) {
    const diffInDays = moment(date2).diff(moment(date1), 'days');
    return Math.abs(diffInDays) <= 45;
  }


  function isYearPassed(date) {
    const givenDate = moment(date, 'YYYY-MM-DD');
    const currentDate = moment(`${years.value}-${months.value}-${days.value}`, 'YYYY-MM-DD');
    const diffInYears = currentDate.diff(givenDate, 'years');
    return diffInYears >= 1;
  }
  function calculatePeriod() {
    const start = moment(`${years.value}-${months.value}-${days.value}`, 'YYYY-MM-DD');
    const end = moment(policy.dateExpiry, 'YYYY-MM-DD');
    const gap = end.diff(start, 'days');

    if (gap >= 90) {
        return  end.format("YYYY-MM-DD");
    } else {
        const daysToComplete90 = 90 - gap;
        return moment(start).add(daysToComplete90+gap,"days").format("YYYY-MM-DD")
        // return { result: '', gap: gap, daysToComplete90: daysToComplete90 };
    }
  }


  function calculate30DaysTerminationDate(notificationDate) {
    const notification = moment(notificationDate, 'YYYY-MM-DD');
    const termination = notification.clone().add(30, 'days');

    // Adjust to the first of the next month
    const firstOfNextMonth = termination
      .clone()
      .startOf('month');
    // Ensure at least 30 days gap
    if (firstOfNextMonth.diff(notification, 'days') < 30) {
      return firstOfNextMonth.add(1, 'month').format('YYYY-MM-DD');
    }

    return firstOfNextMonth.format('YYYY-MM-DD');
  }

  function isWithin90DayPeriod() {
    // Parse the end date and notification date using Moment.js
    const end = moment(policy.dateExpiry, 'YYYY-MM-DD');
    const notification = moment(`${days.value}.${months.value}.${years.value}`, 'DD.MM.YYYY');
    
    // Calculate the date 90 days before the end date
    const ninetyDaysBeforeEnd = end.clone().subtract(90, 'days');
    
    // Check if the notification date is within the 90-day period
    return notification.isSameOrAfter(ninetyDaysBeforeEnd) && notification.isBefore(end);
}


  const terminateContract = async () => {
    setIsUpdatingData(true);
    let mainUrl = 'api/policy/cancel_contract';
    await Request({
      method: 'post',
      route: mainUrl,
      token: token,
      values: {
        type: String(selectedItem.value).toLocaleLowerCase(),
        policyId: policy.policy_id,
        cancelDate: `${days.value}.${months.value}.${years.value}`,
        claimNo: claimsNo || undefined,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('updateContract failed');
        }
        setIsCompleted(true);
        return res.json();
      })
      .then((data) => {
        setIsDone(true);
      })
      .catch((e) => {
        // if (!toast.isActive('error_toast')) {
        showToast(t('somethingWentWrong'), { error: true });
        // }
      })
      .finally(() => {
        setIsUpdatingData(false);
      });
  };


  const calculatePremiumAmount = async (policyId, t, cov, frequency) => {
    let coverage;
    switch (String(selectedCoverage)) {
      case '0.8':
        coverage = '80%';
        break;
      case '0.7':
        coverage = '70%';
        break;
      case '0':
        coverage = '100%';
        break;
      default:
        break;
    }
    let mainUrl = `api/policy/calculate/${policyId}`;
    await Request({
      method: 'GET',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        let coverage;
        switch (String(selectedCoverage)) {
          case '0':
            coverage = 1;
            break;
          default:
            coverage = selectedCoverage;
            break;
        }
        switch (frequency) {
          case 1:
            setPaymentFrequency('month');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 12 * 1.19
              ).toFixed(2)
            );
            break;
          case 3:
            setPaymentFrequency('quarter');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 4 * 1.19
              ).toFixed(2)
            );
            break;
          case 6:
            setPaymentFrequency('halfYear');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 2 * 1.19
              ).toFixed(2)
            );
            break;
          case 12:
            setPaymentFrequency('year');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 1 * 1.19
              ).toFixed(2)
            );
            break;
          default:
            break;
        }
      });
  };

  const checkClaimPeriod = async () => {
    setClaimLoading(true)
    const mainUrl = `api/v2/is_expired?claimNo=${claimsNo}`
    await Request({
      method: 'post',
      route: mainUrl,
      token: token,
      values:{
        notificationDate:`${years.value}.${months.value}.${days.value}`
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('updateContract failed');
        }
        return res.json()
      })
      .then((data) => setClaimsStatus(data.data))
      .finally(() => {
        setClaimLoading(false)
      })
  }

  const confirmPet = React.useMemo(() => {
    return selectedPet
      ? selectedPet.nameOfPet +
      ', ' +
      tariff +
      ' ' +
      selectedCoverage * 100 +
      '%, ' +
      premiumAmount +
      '€'
      : '';
  }, [selectedPet, tariff, premiumAmount]);


  const monthsOptions = React.useMemo(() => {
    return generateMonths(years.label, endDate, startDate);
  }, [years.value]);


  useEffect(() => {
    setDatesValidationError({ status: false, messgae: '' });
    if (!['termination_claim_customer', 'termination_claim_insurer'].includes(selectedItem?.value)) {
      setClaimsStatus(true)
      setClaimsNo("")
    }
  }, [selectedItem]);


  useEffect(() => {
    setDays({
      label: moment().add(1, 'days').format('DD'),
      value: moment().add(1, 'days').format('DD'),
    });
  }, [months]);

  useEffect(()=>{
if(claimsNo.length>6 && [ 'termination_claim_customer','termination_claim_insurer'].includes(selectedItem?.value)){
  checkClaimPeriod()
}
  },[claimsNo,days,months,years])

  useEffect(() => {
    if (policyNo != prevPolicyNo) {
      setSelectedPet(null)
      setClaimLoading(false)
      setClaimsStatus(true)
      setClaimsNo('')
      setPayMonthFrequency(null)
      setSelectedCoverage(null)
      setPremiumAmount(null)
    }
  }, [policyNo])

  useEffect(() => {
    switch (selectedItem?.value) {
      case 'revocation':
        setStartDate(policy.dateInception);
        if (
          !isWithin45Days(
            policy.dateInception,
            `${years.value}-${months.value}-${days.value}`
          )
        ) {
          setDatesValidationError({
            message:
              t('45PeriodNotice'),
            status: true,
          });
        } else {
          setDatesValidationError({ status: false, messgae: '' });
        }
        break;
      case 'ordinary_cancelation':
        setStartDate(policy.dateInception);
        break;
      case 'ordinary_cancelation_insurer':
        setStartDate(policy.dateInception);
        break;
      case 'cancellation_for_other_reasons':
        setStartDate(policy.dateInception);
        break;
      case 'withdrawal_by_the_insurer':
        setStartDate(policy.dateInception);
      break;

      case 'termination_claim_insurer':
        setStartDate(policy.dateInception);

        break;
    }
  }, [selectedItem, years, months, days]);

  useEffect(() => {
    setMonths(
      monthsOptions.some((el) => el.value == months.value)
        ? months
        : monthsOptions[monthsOptions.length - 1]
    );
  }, [monthsOptions]);

  if (isCompleted) {
    return (
      <>
        <div
          className="modal_container__body__text"
          style={{
            maxWidth: '500px',
            margin: '40px 0',
            textAlign: 'start',
          }}
        >
          {t('dataChangedSuccess')}
        </div>

        <div className="modal_container__body__row-modal">
          <div
            className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="wrapper w-100 d-flex gap-4 flex-column h-auto ">
      {CANCELLATION_OR_REVOCATION_FLOW
        // .filter(el => ((policy.policy_id && el?.formType != "search") || el?.formType == "search") ? el : undefined)
        .map((section, index) => {
          return (
            <>
              {section.formType === 'search' ||
                (section.formType === 'result' && policy.policy_id) ? (
                <div
                  key={`section-${index}`}
                  className="section-container w-100"
                >
                  {section.formType === 'result' && isLoadingData ? (
                    <Loading noText fast width={'100%'} scroll={false} />
                  ) : ((selectedPet && section.formType === 'result') || section.formType != 'result') && (
                    <>
                      {Object.entries(section.components).map(
                        ([key, step], stepIndex) => {
                          // Check if step is an object with a type property
                          if (typeof step === 'object' && step.type) {
                            if (
                              (step.type === 'text_input' &&
                                !step.dependency &&
                                !step.dependencies) ||
                              (step.type === 'text_input' &&
                                step.dependency &&
                                step.dependency == selectedItem?.value) ||
                              (step.type === 'text_input' &&
                                step.dependencies &&
                                step.dependencies.includes(selectedItem?.value))
                            ) {
                              return (
                                <div
                                  key={`text-step-${stepIndex}`}
                                  className="modal_container__body__inputs align-items-start"
                                >
                                  <label htmlFor={step.id}>
                                    {t(step.text)}
                                  </label>
                                  <div className='d-flex w-100'>

                                    <input
                                      id={step.id}
                                      className="modal_container__body__input w-100"
                                      placeholder={t(step.placeholder)}
                                      required={step.required || false}
                                      disabled={step.disabled || false}
                                      onChange={(e) =>
                                        section.formType == 'search' &&
                                          !step.disabled
                                          ? step?.dependencies
                                            ? setClaimsNo(e.target.value)
                                            : setPolicyNo(e.target.value)
                                          : setClaimsNo(e.target.value)
                                      }
                                      value={
                                        section.formType == 'search' &&
                                          !step.disabled
                                          ? policyNo
                                          : !step?.dependencies
                                            ? step.disabled ? isLoadingData ? "loading ..." : selectedPet ? confirmPet : "" : ""
                                            : claimsNo
                                      }
                                    />
                                    {isClaimStatusLoading && step.id == "ClaimNO" && (
                                      <div className="loading_container " style={{
                                        right: '2%',
                                        left: 'unset',
                                        width: 'unset',
                                        margin: 0,
                                        padding: 0,
                                        height: '30px',
                                        transform: 'none',
                                        top: 'unset'
                                      }}>
                                        <CircularProgress color="error" style={{ width: "30px", height: "30px" }} />
                                      </div>
                                    )}
                                  </div>
                                  {!claimsStatus && step.id == "ClaimNO" && !isClaimStatusLoading && (
                                    <span
                                      style={{
                                        color: '#FE4B4E',
                                        fontWeight: '800',
                                      }}
                                    >
                                      {t('30PeriodNotice')}
                                    </span>
                                  )}
                                </div>
                              );
                            }

                            if (step.type === 'button') {
                              return (
                                <div
                                  key={`button-step-${stepIndex}`}
                                  className="modal_container__body__inputs align-items-start"
                                >
                                  <button
                                    id={step.id}
                                    onClick={() => {
                                      section.formType == 'search' &&
                                        findContract();
                                    }}
                                    className={`button_red_small__admin ${step.btnType || ''
                                      }`}
                                    disabled={
                                      policyNo?.length !== 9 || isLoadingData
                                    }
                                  >
                                    {t(step.text)}
                                  </button>
                                </div>
                              );
                            }

                            if (step.type == 'select') {
                              const options = step.options.map((el, i) => ({
                                label: t(el.label),
                                value: el.value,
                              }));
                              return (
                                <>
                                  <div
                                    key={`button-step-${stepIndex}`}
                                    className="modal_container__body__inputs align-items-start"
                                  >
                                    <label htmlFor={step.id}>
                                      {t(step.text)}
                                    </label>
                                    <CustomSelect
                                      variant="modal-edit-data"
                                      options={step.options}
                                      value={selectedItem}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('pleaseSelectHere')}
                                      onChange={(e) => setSelectedItem(e)}
                                      isClearable={true}
                                      toTranslate={true}
                                      fullWidth={true}
                                    />
                                  </div>
                                  {/* {(selectedItem?.value === 'termination_claim_customer' ||
                                          selectedItem?.value === 'termination_claim_insurer' ||
                                          selectedItem?.value === 'withdrawal_by_the_insurer') &&
                                          <div
                                            key={`text-step-${stepIndex}`}
                                            className="modal_container__body__inputs align-items-start"
                                          >
                                            <label htmlFor={step.id}>{t(step.text)}</label>
                                            <input
                                              id={`input-claim-${step.id}`}
                                              className="modal_container__body__input w-100"
                                              placeholder={t(claimNo)}
                                              // required={step.required || false}
                                              // disabled={step.disabled || false}
                                              onChange={(e) => setClaimNo(e.target.value)
                                              }
                                              value={claimNo}
                                            />
                                          </div>} */}
                                </>
                              );
                            }

                            if (
                              (step.type == 'date' && !step.dependency) ||
                              (step.dependency &&
                                step.dependency == selectedItem?.value)
                            ) {
                              return (
                                <>
                                  <div
                                    key={`button-step-${stepIndex}`}
                                    className="modal_container__body__inputs align-items-start w-100 "
                                  >
                                    <label htmlFor={step.id}>
                                      {t(step.text)}
                                    </label>
                                    <div className="d-flex gap-3 w-100">
                                      <CustomSelect
                                        variant="modal-edit-data"
                                        options={generateDays(
                                          years.value,
                                          months.value,
                                          startDate
                                        )}
                                        formatCreateLabel={(i) => i}
                                        menuPlacement={'top'}
                                        placeholder={t('DD')}
                                        value={days}
                                        onChange={(e) => setDays(e)}
                                        isClearable={false}
                                        toTranslate={false}
                                      />
                                      <CustomSelect
                                        variant="modal-edit-data"
                                        options={monthsOptions}
                                        formatCreateLabel={(i) => i}
                                        placeholder={t('MM')}
                                        value={months}
                                        menuPlacement={'top'}
                                        onChange={(e) => setMonths(e)}
                                        isClearable={false}
                                        toTranslate={false}
                                      />
                                      <CustomSelect
                                        variant="modal-edit-data"
                                        options={generateYears(
                                          endDate,
                                          startDate
                                        )}
                                        formatCreateLabel={(i) => i}
                                        placeholder={t('YY')}
                                        value={years}
                                        menuPlacement={'top'}
                                        onChange={(e) => setYears(e)}
                                        isClearable={false}
                                        toTranslate={false}
                                      />
                                    </div>
                                    {datesValidationError?.status && (
                                      <span
                                        style={{
                                          color: '#FE4B4E',
                                          fontWeight: '800',
                                        }}
                                      >
                                        {datesValidationError.message}
                                      </span>
                                    )}
                                  </div>
                                </>
                              );
                            }

                            if (
                              (step.type == 'text' && !step.dependency) ||
                              (step.dependency &&
                                step.dependency == selectedItem.value)
                            ) {
                              return (
                                <>
                                  <div
                                    key={`button-step-${stepIndex}`}
                                    className="modal_container__body__inputs align-items-start w-100 "
                                  >
                                    <label htmlFor={step.id}>
                                      {t(step.text)}
                                    </label>
                                    <div className="d-flex gap-3 w-100">
                                      <span style={step.style}>
                                        {getTerminationDate}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          }
                          return null; // Ignore non-object steps or steps without a type
                        }
                      )}
                    </>
                  )}

                  {section.formType === 'result' &&
                    selectedPet &&
                    !isLoadingData && (
                      <>
                        {isUpdatingData ? (
                          <Loading noText fast width={'150px'} scroll={false} />
                        ) : (
                          <button
                            onClick={() => {
                              terminateContract();
                            }}
                            className={`button_red_small__admin `}
                            style={{ marginBottom: '10px' }}
                            disabled={
                              !selectedItem ||
                              !days ||
                              !months ||
                              !years ||
                              datesValidationError.status ||
                              !claimsStatus ||
                              isClaimStatusLoading ||
                              (['termination_claim_customer', 'termination_claim_insurer'].includes(selectedItem.value) && claimsNo.length < 9)
                            }
                          >
                            {t('Bestätigen')}
                          </button>
                        )}
                      </>
                    )}
                </div>
              ) : null}
            </>
          );
        })}

      {errorMessage && (
        <span
          style={{
            color: '#FE4B4E',
            fontSize: '34px',
            fontWeight: '800',
          }}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default CancellationOrRevocation;
