import moment from 'moment';

export const MISSING_OCR_TYPES = {
  diagnosis: 'diagnosis',
  chipnumberDoesNotMatch: 'chipnumber does not match',
  chipnumberNotFound: 'chipnumber not found',
  duplicates: 'duplicates',
};

export const FLOW_TYPES = {
  General: 'General',
  'General to Vets': 'General to Vets',
  'Existing Claim': 'Existing Claim',
  'Cost estimation': 'Cost estimation',
  'Liability claim': 'Liability claim',
};

const handleNext = () => {
  let newSkipped = skipped;
  if (isStepSkipped(activeStep)) {
    newSkipped = new Set(newSkipped.values());
    newSkipped.delete(activeStep);
  }
};

export const CHANGE_PET_DATA_FLOW = {
  petName: {
    id: 'changeName',
    title: 'petNameChange',
    steps: {
      changeName: {
        type: 'text_input',
        text: 'pleaseEnterPetName',
        btnText: 'Send Change Request',
        btnType: 'sendRequest',
        placeholder: 'name',
        id: 'name',
        disablingValue: 'petName',
      },
    },
  },

  petBreed: {
    id: 'petBreed',
    title: 'petBreedChange',
    steps: {
      selectBreedType: {
        isAdmin: true,
        title: 'Select Breed Type',
        type: 'multiSteps',
        btnText: 'Send Change Request',
        btnType: 'next',
        disablingValue: 'petBreed',
        steps: {
          // selectPetType: {
          //   title: 'pleaseSelectPetType',
          //   type: 'radio',
          //   options: [
          //     { label: 'cat', value: 'cat' },
          //     { label: 'dog', value: 'dog' },
          //   ],
          //   id: 'petType',
          // },
          selectBreedType: {
            title: 'pleaseSelectBreedType',
            type: 'radio',
            id: 'breedType',
            options: [
              { label: 'pureBreed', value: 'Pure' },
              { label: 'hybridBeed', value: 'Hybrid' },
              { label: 'unknownBeed', value: 'Unknown' },
            ],
          },
          selectfirstBreed: {
            dependency: 'Pure',
            type: 'breeds-select',
          },
          selectbothBreed: {
            dependency: 'Hybrid',
            type: 'breeds-select',
          },
          selectHeight: {
            options: [
              { label: '0-44cm', value: '0-44cm' },
              { label: '> 44cm', value: '> 44cm' },
            ],
            type: 'radio-options',
          },
        },
      },
      // confirmation: {
      //   isAdmin: false,
      //   title: 'changeBreedMessage',
      //   type: 'confirmation',
      //   options: ['Yes', 'No'],
      // },
    },
  },

  petBirthday: {
    id: 'petBirthday',
    title: 'petBDChange',
    steps: {
      selectBirthday: {
        text: 'pleaseEnterPetBD',
        type: 'date',
        btnText: 'Send Change Request',
        disablingValue: 'petBD',
      },
      uploadFile: {
        text: 'pleaseAttach',
        type: 'upload',
        btnText: 'Next',
      },
      // confirmation: {
      //   isAdmin: false,
      //   title: 'changeHeightMessage',
      //   type: 'confirmation',
      //   options: ['Yes', 'No'],
      // },
    },
  },

  petChipNumber: {
    id: 'petChipNumber',
    title: 'petChipChange',
    steps: {
      enterChipnumber: {
        text: 'pleaseEnterPetChip',
        type: 'text_input',
        // btnType: 'sendRequest',
        btnType: 'next',
        id: 'chipNumber',
        placeholder: 'chipNumber',
        disablingValue: 'petChip',
      },
      uploadFile: {
        text: 'pleaseAttach',
        type: 'upload',
        btnType: 'sendRequest',
      },
    },
  },

  petGender: {
    id: 'petGender',
    title: 'petGenderChange',
    steps: {
      chooseGender: {
        text: 'pleaseSelectPetGender',
        options: [
          { label: 'male', value: 'Male' },
          { label: 'female', value: 'Female' },
        ],
        type: 'radio-options',
        id: 'gender',
        btnText: 'Send Change Request',
        btnType: 'sendRequest',
      },
    },
  },

  petHeight: {
    id: 'petHeight',
    title: 'dogHeightChange',
    steps: {
      chooseHeight: {
        text: 'pleaseSelectDogHeight',
        options: [
          { label: '0-44cm', value: '0-44cm' },
          { label: '> 44cm', value: '> 44cm' },
        ],
        id: 'height',
        type: 'radio-options',
        // btnType: 'next',
        btnType: 'sendRequest',
        btnText: 'Send Change Request',
      },
      // confirmation: {
      //   text: 'changeHeightMessage',
      //   type: 'confirmation',
      //   options: ['Yes', 'No'],
      // },
    },
  },

  catHabitat: {
    id: 'catHabitat',
    title: 'catHabitatChange',
    steps: {
      chooseHeight: {
        text: 'pleaseSelectCatHabitat',
        options: [
          { label: 'Indoor', value: 'Indoor' },
          { label: 'Outdoor', value: 'Outdoor' },
        ],
        id: 'habitat',
        type: 'radio-options',
        // btnType: 'next',
        btnType: 'sendRequest',
        btnText: 'Send Change Request',
      },
      // confirmation: {
      //   text: 'changeHeightMessage',
      //   type: 'confirmation',
      //   options: ['Yes', 'No'],
      // },
    },
  },
};

export const CANCEL_CONTRACT_FLOW = {};
export const LAST_CLAIM_ERROR_MSG = 'Last claim check error';

// function generateYears() {
//   const currentYear = new Date().getFullYear();
//   const years = [];

//   for (let year = currentYear; year >= currentYear - 80; year--) {
//     years.push({ label: year.toString(), value: year });
//   }

//   return years;
// }

// function generateMonths() {
//   const months = [
//     '01',
//     '02',
//     '03',
//     '04',
//     '05',
//     '06',
//     '07',
//     '08',
//     '09',
//     '10',
//     '11',
//     '12',
//   ];

//   return months.map((month, index) => ({ label: month, value: index + 1 }));
// }

// function generateDays(selectedMonth) {
//   const days = [];

//   for (let day = 1; day <= 30; day++) {
//     days.push({ label: day.toString(), value: day });
//   }

//   return days;
// }
function generateYears(endDate,startDate) {
  let currentYear = startDate?startDate.split('-')[0] : new Date().getFullYear();
  let years = [];
  let endD=endDate?endDate:moment().add(20,"years").format("YYYY-MM-DD")
  
  for (let year = currentYear; year <= endD.split('-')[0]; year++) {
      years.push({label:year,value:year});
  }
  return years;
}

function generateMonths(selectedYear, endDate,startDate) {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based
  let endD=endDate?endDate:moment().add(20,"years").format("YYYY-MM-DD")
  
  let endYear = parseInt(endD.split('-')[0]);
  let endMonth = parseInt(endD.split('-')[1]); // JavaScript months are 0-based
  let months = [];
  let startMonth = startDate && startDate.split('-')[0]==selectedYear ? startDate.split('-')[1] :1;
  if (selectedYear === currentYear) {
      for (let month = currentMonth; month <= (selectedYear === endYear ? endMonth : 12); month++) {
          months.push({ label: month.toString(), value: month });
      }
  } else if (selectedYear === endYear) {
      for (let month = startMonth; month <= endMonth; month++) {
          months.push({ label: month.toString(), value: month });
      }
  } else {
      for (let month = startMonth; month <= 12; month++) {
          months.push({ label: month.toString(), value: month });
      }
  }
  return months;
}

function generateDays(year, month, startDate) {
  let startDay = startDate && startDate.split('-')[1] == month && startDate.split('-')[0] == year ? parseInt(startDate.split('-')[2]) : 1;
  let daysInMonth;
  if (month === 2) {
    // Check for leap year
    daysInMonth = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28;
  } else {
    daysInMonth = [4, 6, 9, 11].includes(month) ? 30 : 31;
  }
  return Array.from({ length: daysInMonth - startDay + 1 }, (_, i) => ({ label: (startDay + i).toString(), value: startDay + i }));
}


// // Get the current year
// let currentYear = new Date().getFullYear();

// // Example end date
// let endDate = new Date('2030-12-31');
// let endYear = endDate.getFullYear();

// // Generate years from now to the end year
// let years = generateYears(currentYear, endYear);

// // Generate months
// let months = generateMonths();

// // Generate days for a specific year and month
// let days = generateDays(currentYear, 2); // Example: February of the current year

// console.log("Years:", years);
// console.log("Months:", months);
// console.log("Days in February of current year:", days);

export { generateDays, generateMonths, generateYears };
