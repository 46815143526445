import React, { useEffect, useMemo, useState } from 'react';
import { CHANGE_CONTRACT_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import { isMobile, isMobileOnly } from 'react-device-detect';
import {
  generateDays,
  generateYears,
  generateMonths,
} from '../../../constants/Flow';
import moment from 'moment';
import { getToken } from '../../../config/token';
import { Request } from '../../../config/requests';
import Loading from '../../../components/common/Loading';
import { daysToWeeks } from 'date-fns';

const ChangeContract = ({
  setIsLoading,
  isLoading,
  handleClose,
  setIsDone,
}) => {
  const { t } = useTranslation();
  const token = getToken();
  const [selectedItem, setSelectedItem] = React.useState({
    label: 'E-Mail-Adresse',
    value: 'email',
  });
  const [years, setYears] = useState({
    label: moment().format('yy'),
    value: moment().format('yy'),
  });
  const [months, setMonths] = useState({
    label: moment().format('MM'),
    value: moment().format('MM'),
  });
  const [days, setDays] = useState({
    label: moment().add(1, 'days').format('DD'),
    value: moment().add(1, 'days').format('DD'),
  });
  const [policyNo, setPolicyNo] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);
  const [policy, setPolicy] = useState({});
  const [tariff, setTariff] = useState(null);
  const [initialTariff, setInitialTariff] = useState(null);
  const [payMonthFrequency, setPayMonthFrequency] = useState(null);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [initialCoverage, setInitialCoverage] = useState(null);
  const [premiumAmount, setPremiumAmount] = useState(0.0);
  const [initialPremiumAmount, setInitialPremiumAmount] = useState(0.0);
  const [topicToChange, setTopicToChange] = useState('change_tariff');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [badCoverage, setBadCoverage] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleChange = (id, value) => {
    if (id == 'tariff') {
      setTariff(value);
    } else if (id == 'percent') {
      setSelectedCoverage(value);
    }
  };

  const findContract = async () => {
    setIsLoadingData(true);
    let mainUrl = `api/policy/${policyNo}`;
    let res = await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    });
    res = await res.json();
    findPet(res.policy_id);
    setPolicyId(res.policy_id);
    setPolicy(res);
  };

  const findPet = async (policyId) => {
    let mainUrl = `api/users/v2/pet?policy_id=${policyId}`;
    await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectedPet(data.data);
        setSelectedCoverage(data.products.petInsurance.data.selectedCoverage.toString());
        setTariff(data.products.petInsurance.data.selectedPackage);
        setPayMonthFrequency(data.quote.schedules.payMonthFrequency);
        setInitialCoverage(data.products.petInsurance.data.selectedCoverage.toString())
        setInitialTariff(data.products.petInsurance.data.selectedPackage)
        calculatePremiumAmount(   policyId,
          data.products.petInsurance.data.selectedPackage,
          data.products.petInsurance.data.selectedCoverage,
          data.quote.schedules.payMonthFrequency)
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  };

  const calculatePremiumAmount = async (p =null, t=null, cov=null, frequency=null) => {
    setIsLoadingCalculate(true);
    let coverage;
    switch (String(selectedCoverage)) {
      case '0.8':
        coverage = '80%';
        break;
      case '0.7':
        coverage = '70%';
        break;
      case '0':
        coverage = '100%';
        break;
      default:
        break;
    }
    let mainUrl = `api/policy/calculate/${p || policyId}`;
    await Request({
      method: 'GET',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        let coverage;
        switch (String(cov || selectedCoverage)) {
          case '0':
            coverage = 1;
            break;
          default:
            coverage = cov || selectedCoverage;
            break;
        }
        switch (frequency || payMonthFrequency) {
          default:
          case 1:
            setPaymentFrequency('month');
            setPremiumAmount(
              (
                data[
                  String(t || tariff).split(' ')[0].toLocaleLowerCase() +
                  String(t ||tariff).split(' ')[1]
                ].pricing[cov ||coverage] / 12 * 1.19
              ).toFixed(2)
            );
          if(initialPremiumAmount==0)setInitialPremiumAmount(
            (
              data[
                String(t || tariff).split(' ')[0].toLocaleLowerCase() +
                String(t ||tariff).split(' ')[1]
              ].pricing[cov ||coverage] / 12 * 1.19
            ).toFixed(2)
          );
            break;
          case 3:
            setPaymentFrequency('quarter');
            setPremiumAmount(
              (
                data[
                  String(t || tariff).split(' ')[0].toLocaleLowerCase() +
                  String(t || tariff).split(' ')[1]
                ].pricing[cov ||coverage] / 4 * 1.19
              ).toFixed(2)
            );
            if(initialPremiumAmount==0)setInitialPremiumAmount(premiumAmount)

            break;
          case 6:
            setPaymentFrequency('halfYear');
            setPremiumAmount(
              (
                data[
                  String(t || tariff).split(' ')[0].toLocaleLowerCase() +
                  String(t || tariff).split(' ')[1]
                ].pricing[cov || coverage] / 2 * 1.19
              ).toFixed(2)
            );
            if(initialPremiumAmount==0)setInitialPremiumAmount(premiumAmount)

            break;
          case 12:
            setPaymentFrequency('year');
            setPremiumAmount(
              (
                data[
                  String(t ||tariff).split(' ')[0].toLocaleLowerCase() +
                  String(t || tariff).split(' ')[1]
                ].pricing[cov || coverage] / 1 * 1.19
              ).toFixed(2)
            );
            if(initialPremiumAmount==0)setInitialPremiumAmount(premiumAmount)

            break;
        }
      })
      .finally(() => {
        setIsLoadingCalculate(false);
      });
  };

  const updateContract = async () => {
    setIsUpdatingData(true);
    let mainUrl = 'api/policy/change_contract';
    let coverage;
    switch (String(selectedCoverage)) {
      case '0.8':
        coverage = '80%';
        break;
      case '0.7':
        coverage = '70%';
        break;
      case '1':
        coverage = '100%';
        break;
      default:
        break;
    }
    let mta_date = moment().add(1, 'days').format('DD.MM.YYYY');
    await Request({
      method: 'post',
      route: mainUrl,
      token: token,
      values: {
        newTariff: tariff,
        newCoverage: coverage,
        policyId: policyId,
        mtaDate: `${days.value}.${months.value}.${years.value}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('updateContract failed');
        }
        setIsCompleted(true);
        return res.json();
      })
      .then((data) => {
        if (data.result) {
          setIsDone(true);
        }
      })
      .catch((e) => {
        console.error('updateContract', e);
      })
      .finally(() => {
        setIsUpdatingData(false);
      });
  };

  useEffect(() => {
    if (policyId) calculatePremiumAmount();
  }, [selectedCoverage, tariff]);

  useEffect(() => {
    setDays({
      label: moment().add(1, 'days').format('DD'),
      value: moment().add(1, 'days').format('DD'),
    });
  }, [months]);

  const monthsOptions = React.useMemo(() => {
    return generateMonths(
      years.label,
      policy.dateExpiry
        ? policy.dateExpiry
        : moment().add(10, 'year').format('YYYY-MM-DD')
    );
  }, [years.value]);

  const confirmPet = React.useMemo(() => {
    return selectedPet
      ? selectedPet.nameOfPet +
      ', ' +
      t(initialTariff) +
      ' ' +
      initialCoverage * 100 +
      '%, ' +
      initialPremiumAmount +
      '€'
      : '';
  }, [selectedPet,premiumAmount ]);

  useEffect(() => {
    setMonths(
      monthsOptions.some((el) => el.value == months.value)
        ? months
        : monthsOptions[monthsOptions.length - 1]
    );
  }, [monthsOptions]);

  useEffect(() => {
    setBadCoverage(isNaN(premiumAmount) ? true : false);
  }, [premiumAmount, selectedCoverage, tariff]);

  const isBtnDisabled = (val) => {
    const tariffCell = {
      'OP Basic': ['0.7', '0.8', '1'],
      'OP Comfort': ['0.8', '1'],
      'OP Full': ['1'],
      'Health Basic': ['0.8', '1'],
      'Health Comfort': ['0.8', '1'],
      'Health Full': ['1'],
    };
    if (val.split(' ')[0] === 'OP' || val.split(' ')[0] === 'Health') {
      if (tariffCell[val].includes(selectedCoverage)) {
        return false;
      } else {
        return true;
      }
    } else {
      if (tariffCell[tariff].includes(val)) {
        return false;
      } else {
        return true;
      }
    }
  };

  if (isCompleted) {
    return (
      <>
        <div
          className="modal_container__body__text"
          style={{
            maxWidth: '500px',
            margin: '40px 0',
            textAlign: 'start',
          }}
        >
          {t('dataChangedSuccess')}
        </div>

        <div className="modal_container__body__row-modal">
          <div
            className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className="wrapper w-100 d-flex gap-4 flex-column h-auto "
      style={{ height: 'fit-content', overflow: 'auto' }}
    >
      {CHANGE_CONTRACT_FLOW.map((section, index) => {
        return (
          <>
            {section.formType === 'search' ||
            (section.formType === 'result' && selectedPet !== null) ? (
              <div key={`section-${index}`} className="section-container w-100">
                {section.formType === 'result' && isLoadingData ? (
                  <Loading noText fast width={'100%'} scroll={false} />
                ) : (
                  <>
                    {Object.entries(section.components).map(
                      ([key, step], stepIndex) => {
                        // Check if step is an object with a type property
                        if (typeof step === 'object' && step.type) {
                          if (
                            (step.type === 'text_input' && !step.dependency) ||
                            (step.type === 'text_input' &&
                              step.dependency == selectedItem.value)
                          ) {
                            return (
                              <div
                                key={`text-step-${stepIndex}`}
                                className="modal_container__body__inputs align-items-start"
                              >
                                <label htmlFor={step.id}>{t(step.text)}</label>
                                <input
                                  id={step.id}
                                  className="modal_container__body__input w-100"
                                  placeholder={t(step.placeholder)}
                                  required={step.required || false}
                                  disabled={step.disabled || false}
                                  onChange={(e) =>
                                    section.formType == 'search' &&
                                    !step.disabled &&
                                    setPolicyNo(e.target.value)
                                  }
                                  value={
                                    section.formType == 'search' &&
                                    !step.disabled
                                      ? policyNo
                                      : confirmPet
                                  }
                                />
                              </div>
                            );
                          }

                          if (step.type === 'button') {
                            return (
                              <div
                                key={`button-step-${stepIndex}`}
                                className="modal_container__body__inputs align-items-start"
                              >
                                <button
                                  id={step.id}
                                  disabled={
                                    policyNo?.length !== 9 || isLoadingData
                                  }
                                  className={`button_red_small__admin ${
                                    step.btnType || ''
                                  }`}
                                  onClick={() => {
                                    section.formType == 'search' &&
                                      findContract();
                                  }}
                                >
                                  {t(step.text)}
                                </button>
                              </div>
                            );
                          }

                          if (step.type == 'select') {
                            return (
                              <>
                                <div
                                  key={`button-step-${stepIndex}`}
                                  className="modal_container__body__inputs align-items-start"
                                >
                                  <label htmlFor={step.id}>
                                    {t(step.text)}
                                  </label>
                                  <CustomSelect
                                    variant="modal-edit-data"
                                    options={step.options.map((el) => ({
                                      label: t(el.label),
                                      value: t(el.value),
                                    }))}
                                    value={selectedItem}
                                    onChange={(e) => {
                                      setSelectedItem(e);
                                    }}
                                    isClearable={true}
                                    toTranslate={false}
                                  />
                                </div>
                              </>
                            );
                          }

                          if (
                            (step.type == 'radio-options' &&
                              !step.dependency) ||
                            (step.dependency &&
                              step.dependency == topicToChange)
                          ) {
                            return (
                              <div
                                key={`button-step-${stepIndex}`}
                                className="modal_container__body__inputs align-items-start w-100 "
                              >
                                <label htmlFor={step.id}>{t(step.text)}</label>
                                <div
                                  key={`button-step-${stepIndex}`}
                                  className="modal_container__body__row-modal flex-wrap"
                                  style={{
                                    flexDirection:
                                      step?.direction == 'vertical'
                                        ? 'column'
                                        : 'row',
                                    gap: '15px',
                                    width: '100%',
                                  }}
                                >
                                  {step.options.map((option, optionKey) => {
                                    return (
                                      <button
                                        key={optionKey}
                                        style={{
                                          width:
                                            step?.direction == 'vertical'
                                              ? '100%'
                                              : step.options.length > 3
                                                ? '48%'
                                                : `calc( 93% / ${step.options.length} )`,
                                        }}
                                        className={`button_red_small__admin  ${
                                          isMobile ? 'wide' : ''
                                        }  ${(tariff == option.value || selectedCoverage == option.value || topicToChange == option.value) && 'button_red_small_active'}`}
                                        value={
                                          step.id == 'tariff'
                                            ? tariff
                                            : selectedCoverage
                                        }
                                        onClick={() => {
                                          if (
                                            step.id == 'tariff' ||
                                            step.id == 'percent'
                                          ) {
                                            handleChange(step.id, option.value);
                                          } else {
                                            setTopicToChange(option.value);
                                          }
                                        }}
                                        // disabled={(step.id == "tariff" || step.id == "percent") ? true : false}
                                        disabled={
                                          step.id == 'tariff' ||
                                          step.id == 'percent'
                                            ? isBtnDisabled(option.value)
                                            : false
                                        }
                                      >
                                        {t(option.label)}
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }

                          if (
                            (step.type == 'date' && !step.dependency) ||
                            (step.dependency &&
                              step.dependency == selectedItem.value)
                          ) {
                            return (
                              <>
                                <div
                                  key={`button-step-${stepIndex}`}
                                  className="modal_container__body__inputs align-items-start w-100 "
                                >
                                  <label htmlFor={step.id}>
                                    {t(step.text)}
                                  </label>
                                  <div className="d-flex gap-3 w-100">
                                    <CustomSelect
                                      variant="modal-edit-data"
                                      options={generateDays(
                                        years.value,
                                        months.value
                                      )}
                                      formatCreateLabel={(i) => i}
                                      menuPlacement={'top'}
                                      placeholder={t('DD')}
                                      value={days}
                                      onChange={(e) => setDays(e)}
                                      isClearable={false}
                                      toTranslate={false}
                                    />
                                    <CustomSelect
                                      variant="modal-edit-data"
                                      options={monthsOptions}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('MM')}
                                      value={months}
                                      menuPlacement={'top'}
                                      onChange={(e) => setMonths(e)}
                                      isClearable={false}
                                      toTranslate={false}
                                    />
                                    <CustomSelect
                                      variant="modal-edit-data"
                                      options={generateYears(
                                        policy.dateExpiry.split('-')[0]
                                      )}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('YY')}
                                      value={years}
                                      menuPlacement={'top'}
                                      onChange={(e) => setYears(e)}
                                      isClearable={false}
                                      toTranslate={false}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          }

                          if (
                            (step.type == 'text' && !step.dependency) ||
                            (step.dependency &&
                              step.dependency == selectedItem.value)
                          ) {
                            return (
                              <>
                                {badCoverage ? (
                                  <div
                                    key={`button-step-${stepIndex}`}
                                    className="modal_container__body__inputs w-100 "
                                    style={step.style}
                                  >
                                    {t('badCoverage')}
                                  </div>
                                ) : (
                                  <div
                                    key={`button-step-${stepIndex}`}
                                    className="modal_container__body__inputs align-items-start w-100 "
                                  >
                                    <label htmlFor={step.id}>
                                      {t(step.text)}
                                    </label>
                                    <div className="d-flex gap-3 w-100">
                                      {isLoadingCalculate ? (
                                        <Loading
                                          noText
                                          fast
                                          width={'150px'}
                                          scroll={false}
                                        />
                                      ) : (
                                        <span style={step.style}>
                                          {premiumAmount || step.initialValue}{' '}
                                          €/{t(paymentFrequency)}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }
                        }
                        return null; // Ignore non-object steps or steps without a type
                      }
                    )}

                    {isLoadingData && selectedPet === null && (
                      <Loading noText fast width={'100%'} scroll={false} />
                    )}

                    {section.formType === 'result' && (
                      <>
                        {isUpdatingData ? (
                          <Loading noText fast width={'150px'} scroll={false} />
                        ) : (
                          <button
                            onClick={() => {
                              updateContract();
                            }}
                            className={`button_red_small__admin `}
                            style={{ marginBottom: '10px' }}
                            disabled={
                              isLoadingCalculate ||
                              badCoverage ||
                              !days ||
                              !months ||
                              !years ||
                              (initialCoverage == selectedCoverage &&
                                tariff == initialTariff)
                            }
                          >
                            {t('Bestätigen')}
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default ChangeContract;
