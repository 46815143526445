import React, { useEffect, useRef, useState } from 'react';
import TableContainerComponent from './tableComponents/TableContainerComponent.js';
import { Request } from '../../../config/requests';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.js';
import { CLAIM_PROPS } from '../../../constants/ApplicationConstants.js';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFlowCurrentStep,
  updateFlowQuestions,
  updateFlowCompletion,
  setFlowLoading,
  updateUploadValues,
  updateTableDetails,
} from '../../../config/actions.js';
import { compareData, isEqual, showToast } from '../../../config/helpers';
import i18n from '../../../lang/i18n.js';

function InvoiceTable({
  data,
  index,
  claimIdTemp,
  ocrDetails,
  handleInput,
  ocrKey,
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const currentStep = useSelector((s) => s.flow.flowCurrentStep);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const flowType = useSelector((s) => s.flow.flowType);
  const [themeSetting] = useLocalStorage('theme', 'panda');
  const [searchParams] = useSearchParams();
  const [localLoad, setLocalLoad] = useState(true);
  const [tableDetails, setTableDetails] = useState([]);
  const [detailedPrepared, setDetailedPrepared] = useState(false);
  const [initialTableDetails, setInintialTableDetails] = useState([]);
  const [selectedTableDetails, setSelectedTableDetails] = useState([]);
  const [resultTableDetails, setResultTableDetails] = useState([]);
  const [updatingIndex, setUpdatingIndex] = useState(0);
  const [taxDetails, setTaxDetails] = useState({});

  function rowSelectHandler(rowIndex) {
    setSelectedTableDetails((prevDetails) =>
      prevDetails.map((item, index) => ({
        ...item,
        selected:
          index === rowIndex
            ? item.selected === true
              ? false
              : true
            : item.selected,
      }))
    );
  }

  function resetRowChanges(index, key = null) {
    let newTableDetails = [...tableDetails];
    if (key === null) {
      delete newTableDetails[index].changedValues;
    } else {
      delete newTableDetails[index].changedValues[key];

      // Recalculate depending values
      if (key === CLAIM_PROPS.UNIT_PRICE) {
        const unitPriceValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.UNIT_PRICE] ??
            newTableDetails[index][CLAIM_PROPS.UNIT_PRICE]
          )
            .toString()
            .replace(',', '.')
        );
        const taxValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.TAX] ??
            newTableDetails[index][CLAIM_PROPS.TAX]
          )
            .toString()
            .replace(',', '.')
        );

        if (!isNaN(unitPriceValue) && !isNaN(taxValue)) {
          newTableDetails[index]['changedValues'][CLAIM_PROPS.AMOUNT] =
            Math.round(unitPriceValue * (1 + taxValue / 100) * 100) / 100;
        }
      } else if (key === CLAIM_PROPS.TAX) {
        const unitPriceValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.UNIT_PRICE] ??
            newTableDetails[index][CLAIM_PROPS.UNIT_PRICE]
          )
            .toString()
            .replace(',', '.')
        );
        const taxValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.TAX] ??
            newTableDetails[index][CLAIM_PROPS.TAX]
          )
            .toString()
            .replace(',', '.')
        );

        if (!isNaN(unitPriceValue) && !isNaN(taxValue)) {
          newTableDetails[index]['changedValues'][CLAIM_PROPS.AMOUNT] =
            Math.round(unitPriceValue * (1 + taxValue / 100) * 100) / 100;
        }
      } else if (key === CLAIM_PROPS.AMOUNT) {
        const amountValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.AMOUNT] ??
            newTableDetails[index][CLAIM_PROPS.AMOUNT]
          )
            .toString()
            .replace(',', '.')
        );
        const taxValue = parseFloat(
          (
            newTableDetails[index]['changedValues'][CLAIM_PROPS.TAX] ??
            newTableDetails[index][CLAIM_PROPS.TAX]
          )
            .toString()
            .replace(',', '.')
        );

        if (!isNaN(amountValue) && !isNaN(taxValue)) {
          newTableDetails[index]['changedValues'][CLAIM_PROPS.UNIT_PRICE] =
            Math.round((amountValue / (1 + taxValue / 100)) * 100) / 100;
        }
      }

      // Cleanup redundant properties
      for (const changedKey in newTableDetails[index]['changedValues']) {
        const changedValue =
          newTableDetails[index]['changedValues'][changedKey];
        const initValue = newTableDetails[index][changedKey];

        if (changedValue === initValue) {
          delete newTableDetails[index]['changedValues'][changedKey];
        }
      }
    }
    setTableDetails(newTableDetails);
  }

  function deleteNewLine(rowIndex) {
    let newTableDetails = [...tableDetails];
    newTableDetails.splice(rowIndex, 1);
    setTableDetails(newTableDetails);
  }

  function handleUpdate(index, key, event) {
    let originTableDetails = structuredClone(initialTableDetails);
    let newTableDetails = [...tableDetails];

    let newValue = event.target.value;
    // newValue = newValue ? newValue : "0";

    // Check for numeric fields
    const isNumericKey = [
      CLAIM_PROPS.UNIT_PRICE,
      CLAIM_PROPS.TAX,
      CLAIM_PROPS.AMOUNT,
      CLAIM_PROPS.QUANTITY,
    ].includes(key);

    // Allow digits, optional single dot/comma, and trailing dot/comma for numeric fields
    if (isNumericKey) {
      if (!/^\d*[.,]?\d*$/.test(newValue)) {
        return;
      }
    }

    // Allow date input in German format like 'DD.MM.YYYY'
    if (key === CLAIM_PROPS.DATE) {
      if (!/^\d{0,2}(\.\d{0,2}(\.\d{0,4})?)?$/.test(newValue)) {
        return;
      }
    }

    if (newTableDetails[index]?.IsNewLine) {
      // Handle edit logic for new lines
      const numericValue = parseFloat(newValue.replace(',', '.'));
      newTableDetails[index][key] = newValue;

      if (isNumericKey && !isNaN(numericValue)) {
        if (key === CLAIM_PROPS.UNIT_PRICE) {
          const taxValue = parseFloat(
            newTableDetails[index][CLAIM_PROPS.TAX].toString().replace(',', '.')
          );

          if (!isNaN(taxValue)) {
            newTableDetails[index][CLAIM_PROPS.AMOUNT] =
              Math.round(numericValue * (1 + taxValue / 100) * 100) / 100;
          }
        } else if (key === CLAIM_PROPS.TAX) {
          const unitPriceValue = parseFloat(
            newTableDetails[index][CLAIM_PROPS.UNIT_PRICE]
              .toString()
              .replace(',', '.')
          );

          if (!isNaN(unitPriceValue)) {
            newTableDetails[index][CLAIM_PROPS.AMOUNT] =
              Math.round(unitPriceValue * (1 + numericValue / 100) * 100) / 100;
          }
        } else if (key === CLAIM_PROPS.AMOUNT) {
          const taxValue = parseFloat(
            newTableDetails[index][CLAIM_PROPS.TAX].toString().replace(',', '.')
          );

          if (!isNaN(taxValue)) {
            newTableDetails[index][CLAIM_PROPS.UNIT_PRICE] =
              Math.round((numericValue / (1 + taxValue / 100)) * 100) / 100;
          }
        }
      }
      setTableDetails(newTableDetails);
    } else {
      // Handle logic recognized lines
      const originalValue = originTableDetails[index][key];

      if (originalValue !== newValue) {
        newTableDetails[index]['changedValues'] = {
          ...newTableDetails[index]['changedValues'],
          [key]: newValue,
        };

        // Recalculate depending values
        if (isNumericKey) {
          const numericValue = parseFloat(newValue.replace(',', '.'));

          if (!isNaN(numericValue)) {
            if (key === CLAIM_PROPS.UNIT_PRICE) {
              const taxValue = parseFloat(
                (
                  newTableDetails[index]['changedValues'][CLAIM_PROPS.TAX] ??
                  newTableDetails[index][CLAIM_PROPS.TAX]
                )
                  .toString()
                  .replace(',', '.')
              );

              if (!isNaN(taxValue)) {
                newTableDetails[index]['changedValues'][CLAIM_PROPS.AMOUNT] =
                  Math.round(numericValue * (1 + taxValue / 100) * 100) / 100;
              }
            } else if (key === CLAIM_PROPS.TAX) {
              const unitPriceValue = parseFloat(
                (
                  newTableDetails[index]['changedValues'][CLAIM_PROPS.UNIT_PRICE] ??
                  newTableDetails[index][CLAIM_PROPS.UNIT_PRICE]
                )
                  .toString()
                  .replace(',', '.')
              );

              if (!isNaN(unitPriceValue)) {
                newTableDetails[index]['changedValues'][CLAIM_PROPS.AMOUNT] =
                  Math.round(unitPriceValue * (1 + numericValue / 100) * 100) / 100;
              }
            } else if (key === CLAIM_PROPS.AMOUNT) {
              const taxValue = parseFloat(
                (
                  newTableDetails[index]['changedValues'][CLAIM_PROPS.TAX] ??
                  newTableDetails[index][CLAIM_PROPS.TAX]
                )
                  .toString()
                  .replace(',', '.')
              );

              if (!isNaN(taxValue)) {
                newTableDetails[index]['changedValues'][CLAIM_PROPS.UNIT_PRICE] =
                  Math.round((numericValue / (1 + taxValue / 100)) * 100) / 100;
              }
            }
          }
        }
      } else {
        delete newTableDetails[index]['changedValues'][key];
      }

      // Cleanup redundant properties
      for (const changedKey in newTableDetails[index]['changedValues']) {
        const changedValue =
          newTableDetails[index]['changedValues'][changedKey];
        const initValue = originTableDetails[index][changedKey];

        if (changedValue === initValue) {
          delete newTableDetails[index]['changedValues'][changedKey];
        }
      }
      setTableDetails(newTableDetails);
    }
  }

  function handleNew() {
    let newTableDetails = [...tableDetails];
    const id = 'id' + Math.random().toString(16).slice(2);
    newTableDetails.push({
      Amount: 0,
      Description: '',
      Currency: null,
      Quantity: 1,
      Tax: 19,
      UnitPrice: 0,
      Pet: currentPetInfo?.nameOfPet,
      Date: '',
      GOT: '',
      IdToCompare: id,
      IsNewLine: true,
    });
    setTableDetails(newTableDetails);
  }

  async function approveTable() {
    try {
      const comparedData = compareData(initialTableDetails, resultTableDetails);
      dispatch(updateTableDetails(comparedData));

      await Request({
        method: 'post',
        route: 'api/claims/update-claim',
        values: {
          id: claimIdTemp ? claimIdTemp : data.claimId,
          tableDetails: comparedData,
          invoiceChanged: !isEqual(resultTableDetails, initialTableDetails),
        },
      });
      const updatedDetails = {
        ...ocrDetails,
        details: {
          ...ocrDetails.details,
          Items: {
            ...ocrDetails.details.Items,
            values: comparedData.map((el) => {
              return { properties: el };
            }),
          },
        },
      };
      dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
      if (flowQuestions.length - 1 === currentStep) {
        dispatch(setFlowLoading(true));
        const manual = flowQuestions.flat().map((i) => ({
          name: i.content.title,
          value: i.content.answer,
          selected: i.content.selected,
        }));
        const currentLanguage = i18n.language;
        const res = await Request({
          method: 'post',
          route: `api/claims/update-claim?policy_id=${id}&lang=${currentLanguage}`,
          values: {
            id: searchParams.get('claimId'),
            type: flowType,
            approve: true,
            manual,
          },
        });
        const data = await res.json();
        if (data.flow && data.flow.data) {
          const content = data?.flow?.data.content.map((i, ind) => {
            const currData = data?.flow?.data.autofill[ind];
            i.group = currData.group;
            if (i.type === 'upload') {
              i.content.ocr = currData.ocr;
              i.content.uniqueId = currData.uniqueId;
            }
            if (i.type === 'dropdown') {
              i.content.selected = currData.selected;
            }
            i.content.isOcr = currData.isOcr;
            i.content.uploadId = currData.uploadId;
            i.content.defaultValue = currData.defaultValue;
            i.uniqueId = currData.uniqueId;
            i.content.groupTitle = currData.groupTitle;
            i.content.width = currData.width;
            return i;
          });
          const status = content[content.length - 1].content.value;
          localStorage.setItem('flowStatus', status);
          const d =
            content.filter(
              (i) =>
                ![
                  'new_claim',
                  'type',
                  'status',
                  'existing_claim',
                  'trigger',
                ].includes(i.type)
            ) || [];
          const groups = d.reduce((acc, i) => {
            return (acc = acc.includes(i.group) ? acc : [...acc, i.group]);
          }, []);
          const values = groups
            .map((i) => {
              return d.filter(
                (j) =>
                  j.group === i &&
                  j.type !== 'existing_claim' &&
                  j.type !== 'trigger'
              );
            })
            .filter((arr) => arr.length > 0);
          dispatch(updateFlowQuestions(values));
          dispatch(updateFlowCurrentStep(0));
          dispatch(updateFlowCompletion(true));
        } else {
          dispatch(setFlowLoading(false));
          dispatch(updateFlowCurrentStep(0));
          showToast('The claim has been registered successfully');
          history('/flow-completed');
        }
        dispatch(setFlowLoading(false));
      } else {
        await handleInput([], index + 1, 'manual');
        const updatedDetails = {
          ...ocrDetails,
          details: {
            ...ocrDetails.details,
            Items: {
              ...ocrDetails.details.Items,
              values: comparedData.map((el) => {
                return { properties: el };
              }),
            },
          },
        };
        dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
        dispatch(setFlowLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function backHandler() {
    handleInput([], index - 1);
    const updatedDetails = {
      ...ocrDetails,
      details: {
        ...ocrDetails.details,
        Items: {
          ...ocrDetails.details.Items,
          values: tableDetails.map((el) => {
            return { properties: el };
          }),
        },
      },
    };
    dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
  }

  useEffect(() => {
    if (tableDetails) {
      setSelectedTableDetails(tableDetails);
    }
  }, [tableDetails]);

  useEffect(() => {
    if (selectedTableDetails) {
      setResultTableDetails(
        selectedTableDetails?.filter((el) => el.selected !== true)
      );
    }
  }, [selectedTableDetails]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const subTotal = ocrDetails.details?.SubTotal?.value?.amount || null;
    const invoiceTotal = ocrDetails.details?.InvoiceTotal?.value?.amount || null;
    const taxAmount = ocrDetails.details?.TaxDetails?.values[0]?.properties?.Amount?.value?.amount || null;
    const taxRate = ocrDetails.details?.TaxDetails?.values[0]?.properties?.Rate?.value
      ? parseFloat(ocrDetails.details?.TaxDetails?.values[0]?.properties?.Rate?.value)
      : null;

    // console.log('IT >>> ocrDetails', ocrDetails)
    // console.log("IT --> subTotal", subTotal)
    // console.log("IT --> invoiceTotal", invoiceTotal)
    // console.log("IT --> taxAmount", taxAmount)
    // console.log("IT --> taxRate", taxRate)

    setTaxDetails({
      subTotal: subTotal,
      invoiceTotal: invoiceTotal,
      taxAmount: taxAmount,
      taxRate: taxRate,
    });

    if (!ocrDetails.details) return;

    if (!detailedPrepared) {
      let tableDetailsPrep = [];
      let tableDetailsAnalyze = [];
      let basedOn = '';

      // 1. Handle recognized values from ocrDetails to tableDetailsAnalyze
      for (let i = 0; i < ocrDetails?.details?.Items?.values?.length; i++) {
        let testRow = ocrDetails.details.Items.values[i].properties;

        [
          CLAIM_PROPS.UNIT_PRICE,
          CLAIM_PROPS.AMOUNT,
          CLAIM_PROPS.TAX,
          CLAIM_PROPS.TAX_RATE,
        ].map((k) => {
          if (ocrDetails.details.Items.values[i].properties[k]) {
            if (typeof ocrDetails.details.Items.values[i].properties[k] === 'object') {
              testRow[k === CLAIM_PROPS.TAX_RATE ? CLAIM_PROPS.TAX : k] = Number(
                ocrDetails.details.Items.values[i].properties[k]?.value?.amount ||
                ocrDetails.details.Items.values[i].properties[k]?.value
              );
            } else {
              testRow[k] = ocrDetails.details.Items.values[i].properties[k];
            }
          } else {
            testRow[k] = 0;
          }
        });

        testRow[CLAIM_PROPS.QUANTITY] =
          testRow?.[CLAIM_PROPS.QUANTITY]?.value ||
          testRow?.[CLAIM_PROPS.QUANTITY] || 1;

        tableDetailsAnalyze.push(testRow);
      }
      // console.log('IT >>> tableDetailsAnalyze', tableDetailsAnalyze)

      // 2. Calculate total UnitPrice and total Amount basing on UnitPrice/Amount in every item
      const totalUP =
        Math.round(
          tableDetailsAnalyze.reduce((acc, el) => {
            console.log('el?.[CLAIM_PROPS.UNIT_PRICE]', el?.[CLAIM_PROPS.UNIT_PRICE])
            return acc + +el?.[CLAIM_PROPS.UNIT_PRICE];
          }, 0) * 100
        ) / 100;

      const totalAmount =
        Math.round(
          tableDetailsAnalyze.reduce((acc, el) => {
            console.log('el?.[CLAIM_PROPS.AMOUNT]', el?.[CLAIM_PROPS.AMOUNT])
            return acc + +el?.[CLAIM_PROPS.AMOUNT];
          }, 0) * 100
        ) / 100;

      // console.log("IT > totalUP", totalUP)
      // console.log("IT > totalAmount", totalAmount)

      // 3. Check if totalUnitPrice about invoiceTotal value (total brutto) or totalAmount about subTotal value (total netto) -> that means we need swap values between UnitPrice / Amount (set flag basedOn = 'Amount';)
      if (
        (invoiceTotal &&
          totalUP &&
          totalUP >= 0.95 * invoiceTotal &&
          totalUP <= 1.05 * invoiceTotal) ||
        (subTotal &&
          totalAmount &&
          totalAmount >= 0.95 * subTotal &&
          totalAmount <= 1.05 * subTotal)
      ) {
        console.log('Amount <--> UP');
        basedOn = 'Amount';
      }

      // if (totalUP && invoiceTotal && (totalUP * 1.19 >= 0.95 * invoiceTotal && totalUP * 1.19 <= 1.05 * invoiceTotal)) {
      //   console.log("UP correct");
      // }

      // if ((totalAmount >= 0.95 * invoiceTotal && totalAmount <= 1.05 * invoiceTotal)) {
      //   console.log("Amount correct");
      // }

      // 4. Transforming data into tableDetails
      for (let i = 0; i < ocrDetails?.details?.Items?.values?.length; i++) {
        let tempRow = ocrDetails.details.Items.values[i].properties;

        tempRow[CLAIM_PROPS.PET] = currentPetInfo.nameOfPet;
        const id = 'id' + Math.random().toString(16).slice(2);
        tempRow.IdToCompare = id;
        tempRow.IsNewLine =
          tempRow?.IsNewLine && !tempRow.hasOwnProperty('changedValues')
            ? tempRow?.IsNewLine
            : false;

        tempRow[CLAIM_PROPS.DATE] =
          tempRow[CLAIM_PROPS.DATE]?.content || tempRow[CLAIM_PROPS.DATE] || '';

        if (typeof tempRow[CLAIM_PROPS.DESCRIPTION] === 'object') {
          tempRow[CLAIM_PROPS.DESCRIPTION] =
            tempRow[CLAIM_PROPS.DESCRIPTION]?.content;
        }
        tempRow[CLAIM_PROPS.QUANTITY] =
          tempRow?.[CLAIM_PROPS.QUANTITY]?.value ||
          tempRow?.[CLAIM_PROPS.QUANTITY] ||
          1;

        if (ocrDetails.details.Items.values[i].properties[CLAIM_PROPS.AMOUNT]?.value?.currencySymbol) {
          tempRow[CLAIM_PROPS.CURRENCY] =
            ocrDetails.details.Items.values[i].properties[CLAIM_PROPS.AMOUNT]?.value?.currencySymbol;
        } else {
          tempRow[CLAIM_PROPS.CURRENCY] = null;
        }

        [
          CLAIM_PROPS.UNIT_PRICE,
          CLAIM_PROPS.AMOUNT,
          CLAIM_PROPS.TAX,
          CLAIM_PROPS.TAX_RATE,
        ].map((k) => {
          if (ocrDetails.details.Items.values[i].properties[k]) {
            if (typeof ocrDetails.details.Items.values[i].properties[k] === 'object') {
              tempRow[k === CLAIM_PROPS.TAX_RATE ? CLAIM_PROPS.TAX : k] = Number(
                ocrDetails.details.Items.values[i].properties[k]?.value?.amount ||
                ocrDetails.details.Items.values[i].properties[k]?.value
              );
            } else {
              tempRow[k] = ocrDetails.details.Items.values[i].properties[k];
            }
          } else {
            tempRow[k] = 0;
          }
        });

        if (!tempRow.Tax) {
          if (taxRate) {
            tempRow.Tax = taxRate;
          } else {
            tempRow.Tax = 19;
          }
        }

        // 5. If flag basedOn equals 'Amount' swap Amount to UnitPrice and recalculate Amount (using UnitPrice and Tax)
        if (basedOn === 'Amount') {
          tempRow[CLAIM_PROPS.UNIT_PRICE] = tempRow[CLAIM_PROPS.AMOUNT];
          tempRow[CLAIM_PROPS.AMOUNT] =
            Math.round(
              tempRow[CLAIM_PROPS.UNIT_PRICE] *
              (1 + tempRow[CLAIM_PROPS.TAX] / 100) *
              100
            ) / 100;
        }

        // 6. Else in case no UnitPrice or UnitPrice equals Amount - calculate UnitPrice (using Amount and Tax)
        if (!tempRow[CLAIM_PROPS.UNIT_PRICE] || tempRow[CLAIM_PROPS.UNIT_PRICE] == tempRow[CLAIM_PROPS.AMOUNT]) {
          tempRow[CLAIM_PROPS.UNIT_PRICE] =
            Math.round(
              (tempRow[CLAIM_PROPS.AMOUNT] / (1 + tempRow.Tax / 100)) * 100
            ) / 100;
        }

        tableDetailsPrep.push(tempRow);
      }
      setTableDetails(tableDetailsPrep);
      if (initialTableDetails.length === 0) {
        setInintialTableDetails(structuredClone(tableDetailsPrep));
      }
    }
    setLocalLoad(false);

    setDetailedPrepared(true);
  }, [ocrDetails]);

  return (
    <>
      {localLoad && (
        <div className="QuestionContainer">
          <Loading noText subText={t('loaderSubText2')} />
        </div>
      )}

      {!localLoad && (
        <div className="InvoiceTableCointainer">
          <TableContainerComponent
            taxDetails={taxDetails}
            tableDetails={tableDetails}
            initialTableDetails={initialTableDetails}
            selectedTableDetails={selectedTableDetails}
            resultTableDetails={resultTableDetails}
            handleUpdate={handleUpdate}
            handleNew={handleNew}
            themeSetting={themeSetting}
            setTableDetails={setTableDetails}
            rowSelectHandler={rowSelectHandler}
            updatingIndex={updatingIndex}
            setUpdatingIndex={setUpdatingIndex}
            resetRowChanges={resetRowChanges}
            deleteNewLine={deleteNewLine}
            ref={ref}
            backHandler={backHandler}
            approveTable={approveTable}
          />
        </div>
      )}
    </>
  );
}

export default InvoiceTable;
