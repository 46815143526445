import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import FullScreen from '../../../assets/iconsSmall/full-screen.svg';
import Collapse from '../../../assets/iconsSmall/collapse.svg';
import Close from '../../../assets/iconsSmall/close.svg';
import PandaMain from '../../../assets/UserInfo/panda-logo-main.svg';
import ArrowLeft from '../../../assets/PetPage/arrow_left_dashed_small.svg';
import ArrowRight from '../../../assets/PetPage/arrow_right_dashed.svg';
import AddLine from '../../../assets/iconsSmall/add_line.svg';
import DeleteLine from '../../../assets/iconsSmall/delete_line.svg';
import ResetChanges from '../../../assets/iconsSmall/close_btn_dark-red.svg';
import { isMobile, isMobileOnly } from 'react-device-detect';
import TableRestyle from '../../ocr/invoiceTable/tableComponents/TableRestyle';
import {
  Input,
  InputAdornment,
  Pagination,
  PaginationItem,
} from '@mui/material';
import { ReactComponent as ArrowLeftRed } from '../../../assets/iconsSmall/arrow_left_solid_red.svg';
import { ReactComponent as ArrowRightRed } from '../../../assets/iconsSmall/arrow_right_solid_red.svg';
import { numToStringConverter } from '../../../utils/utils';

function ModalEditableInvoice({
  openModalEditableInvoice,
  setOpenModalEditableInvoice,
  themeSetting,
  tableDetails,
  setTableDetails,
  initialTableDetails,
  handleUpdate,
  selectedTableDetails,
  resultTableDetails,
  handleNew,
  petName,
  updatingIndex,
  setUpdatingIndex,
  resetRowChanges,
  deleteNewLine,
  currentClaim,
  setCurrentClaim,
}) {
  const style = {
    position: 'absolute',
    left: '50%',
    width: isMobile ? '90%' : '80%',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: '#FFF9F0 !important',
    padding: isMobile ? '20px' : '40px',
    transform: 'translateX(-50%)',
    margin: '5vh 0 0 0',
    outline: 'none',
  };

  const styleFS = {
    position: isMobile ? 'absolute' : 'relative',
    top: isMobile ? '50%' : '',
    left: isMobile ? '50%' : '',
    transform: isMobile ? 'translate(-50%, -50%)' : '',
    width: '100%',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: '#FFF9F0 !important',
    padding: isMobile ? '20px' : '40px',
    outline: 'none',
  };

  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const [hasEmptyInputs, setHasEmptyInputs] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [editingField, setEditingField] = useState(null);

  const decreasePageNumber = () => {
    if (currentClaim > 1) {
      setCurrentClaim(currentClaim - 1);
    }
  };

  const increasePageNumber = () => {
    if (currentClaim < tableDetails.length) {
      setCurrentClaim(currentClaim + 1);
    }
  };

  const handlePage = (event, value) => {
    setCurrentClaim(value);
  };

  function handleClose() {
    setOpenModalEditableInvoice(false);
  }

  function handleResetCellChanges(index, cell) {
    resetRowChanges(index, cell);
  }

  function handleAddNew() {
    handleNew();
    setCurrentClaim(tableDetails.length + 1);
  }

  function handleDeleteLine(index) {
    deleteNewLine(index);
    setCurrentClaim(tableDetails.length - 1);
  }

  function handleResetChanges() {
    setTableDetails(structuredClone(initialTableDetails));
    setCurrentClaim(1);
  }

  useEffect(() => {
    setIsTouched(
      JSON.stringify(initialTableDetails) !== JSON.stringify(tableDetails)
    );
    for (const obj of tableDetails) {
      if (obj.changedValues) {
        const hasEmptyValue = Object.values(obj.changedValues).some(
          (value) => value === '' || value === null || value === undefined
        );
        if (hasEmptyValue) {
          setHasEmptyInputs(true);
          break;
        } else {
          setHasEmptyInputs(false);
        }
      }
      if (obj.IsNewLine === true) {
        const hasEmptyValue = [
          'Date',
          'Description',
          'Amount',
          'UnitPrice',
          'Quantity',
          'Tax',
        ].some((key) => {
          const value = obj[key];
          return value === null || value === '' || value === undefined;
        });
        if (hasEmptyValue) {
          setHasEmptyInputs(true);
          break;
        } else {
          setHasEmptyInputs(false);
        }
      }
    }
  }, [tableDetails]);

  return (
    <div className="ContainerVendor" data-theme={themeSetting}>
      <Modal
        open={openModalEditableInvoice}
        onClose={handleClose}
        className="ModalCustom"
      >
        <Box sx={isExpand ? styleFS : style} data-theme={themeSetting}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0 20px 0',
            }}
          >
            <div>
              <button
                className="btn-reset"
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand ? (
                  <img
                    src={Collapse}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                ) : (
                  <img
                    src={FullScreen}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                )}
              </button>
              <button
                className="btn-reset"
                onClick={() => {
                  setTableDetails(structuredClone(initialTableDetails));
                  handleClose();
                }}
              >
                <img
                  src={Close}
                  width={isMobile ? 18 : 34}
                  height={isMobile ? 18 : 34}
                />
              </button>
            </div>
          </Box>

          <div className="invoice-table-title column-centered">
            <div className="invoice-table-title__title">
              {t('invoiceDetails')}
            </div>
            <div className="invoice-table-title__name">
              {petName || t('Name')}
            </div>
          </div>
          <div
            className="invoice-table-message column-centered"
            style={{ margin: '5vh 0 0 0' }}
          >
            {t('justClick')}
          </div>

          {isMobile ? (
            <div className="tile-table-wrapper-modal">
              <div className="SlowShow policy-table-mobile">
                <div className="policy-table-mobile__header">
                  <div
                    className="policy-table-mobile__back"
                    style={currentClaim < 2 ? { opacity: '0.5' } : {}}
                  >
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={decreasePageNumber}
                    >
                      <img src={ArrowLeft} alt="ArrowLeft" />
                    </div>
                  </div>
                  <div className="policy-table-mobile__img">
                    <img
                      src={PandaMain}
                      alt="Panda_Main_Logo"
                      className="main-logo-policy__mobile"
                    />
                  </div>
                  <div
                    className="policy-table-mobile__next"
                    style={
                      currentClaim == tableDetails?.length
                        ? { opacity: '0.5' }
                        : {}
                    }
                  >
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={increasePageNumber}
                    >
                      <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t('date')}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{
                      backgroundColor:
                        editingField === 'Date' ||
                          tableDetails?.[currentClaim - 1]?.changedValues?.Date ||
                          tableDetails?.[currentClaim - 1]?.changedValues?.Date === ''
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('Date')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'Date'
                            ? 'var(--b-color-additional-1)'
                            : tableDetails?.[currentClaim - 1]?.changedValues?.Date !== null &&
                              tableDetails?.[currentClaim - 1]?.changedValues?.Date !== undefined
                              ? 'var(--b-color-bg-main-2)'
                              : '#312f2f',
                        backgroundColor:
                          editingField === 'Date'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      onChange={(e) =>
                        handleUpdate(currentClaim - 1, 'Date', e)
                      }
                      placeholder={t('datePlaceholder')}
                      value={
                        tableDetails?.[currentClaim - 1]?.changedValues?.Date
                          ? tableDetails?.[currentClaim - 1]?.changedValues?.Date
                          : tableDetails?.[currentClaim - 1]?.changedValues?.Date === ''
                            ? tableDetails?.[currentClaim - 1]?.changedValues?.Date
                            : tableDetails?.[currentClaim - 1]?.Date || ''
                      }
                    />
                    {tableDetails?.[currentClaim - 1]?.changedValues?.Date ||
                      tableDetails?.[currentClaim - 1]?.changedValues?.Date === ''
                      ? (
                        <img
                          src={ResetChanges}
                          alt="Reset changes"
                          className="reset-btn"
                          onClick={() =>
                            handleResetCellChanges(currentClaim - 1, 'Date')
                          }
                        />
                      ) : null}
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t('desc')}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{
                      backgroundColor:
                        editingField === 'Description' ||
                          tableDetails?.[currentClaim - 1]?.changedValues?.Description ||
                          tableDetails?.[currentClaim - 1]?.changedValues?.Description === ''
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('Description')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'Description'
                            ? 'var(--b-color-additional-1)'
                            : tableDetails?.[currentClaim - 1]?.changedValues?.Description !== null &&
                              tableDetails?.[currentClaim - 1]?.changedValues?.Description !== undefined
                              ? 'var(--b-color-bg-main-2)'
                              : '#312f2f',
                        backgroundColor:
                          editingField === 'Description'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      multiline
                      maxRows={4}
                      onChange={(e) =>
                        handleUpdate(currentClaim - 1, 'Description', e)
                      }
                      placeholder={t('addDescr')}
                      value={
                        tableDetails?.[currentClaim - 1]?.changedValues?.Description
                          ? tableDetails?.[currentClaim - 1]?.changedValues?.Description
                          : tableDetails?.[currentClaim - 1]?.changedValues
                            ?.Description === ''
                            ? tableDetails?.[currentClaim - 1]?.changedValues?.Description
                            : tableDetails?.[currentClaim - 1]?.Description ||
                            ''
                      }
                    />
                    {tableDetails?.[
                      currentClaim - 1
                    ]?.changedValues?.hasOwnProperty('Description') ? (
                      <img
                        src={ResetChanges}
                        alt="Reset changes"
                        className="reset-btn"
                        onClick={() =>
                          handleResetCellChanges(
                            currentClaim - 1,
                            'Description'
                          )
                        }
                      />
                    ) : null}
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">
                    {t('quantity2')}
                  </div>
                  <div
                    className="policy-table-mobile__right"
                    style={{
                      backgroundColor:
                        editingField === 'Quantity' ||
                          (tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== null &&
                            tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== undefined)
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('Quantity')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'Quantity'
                            ? 'var(--b-color-additional-1)'
                            : tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== null &&
                              tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== undefined
                              ? 'var(--b-color-bg-main-2)'
                              : '#312f2f',
                        backgroundColor:
                          editingField === 'Quantity'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      type="text"
                      align="center"
                      onChange={(e) =>
                        handleUpdate(currentClaim - 1, 'Quantity', e)
                      }
                      placeholder="-"
                      value={
                        numToStringConverter(tableDetails?.[currentClaim - 1]?.changedValues?.Quantity ??
                          tableDetails?.[currentClaim - 1]?.Quantity)
                      }
                    />
                    {tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== null &&
                      tableDetails?.[currentClaim - 1]?.changedValues?.Quantity !== undefined
                      ? (
                        <img
                          src={ResetChanges}
                          alt="Reset changes"
                          className="reset-btn"
                          onClick={() =>
                            handleResetCellChanges(currentClaim - 1, 'Quantity')
                          }
                        />
                      ) : null}
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">
                    {t('unitPrice')}
                  </div>
                  <div
                    className="policy-table-mobile__right"
                    style={{
                      backgroundColor:
                        editingField === 'UnitPrice' ||
                          (tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== null &&
                            tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== undefined)
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('UnitPrice')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'UnitPrice'
                            ? 'var(--b-color-additional-1)'
                            : tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== null &&
                              tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== undefined
                              ? 'var(--b-color-bg-main-2)'
                              : '#312f2f',
                        backgroundColor:
                          editingField === 'UnitPrice'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      type="text"
                      align="center"
                      placeholder="-"
                      onChange={(e) =>
                        handleUpdate(currentClaim - 1, 'UnitPrice', e)
                      }
                      value={
                        numToStringConverter(tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice ??
                          tableDetails?.[currentClaim - 1]?.UnitPrice)
                      }
                      endAdornment={
                        <InputAdornment position="start">
                          {tableDetails?.[currentClaim - 1]?.Currency == '$'
                            ? '$'
                            : '€'}
                        </InputAdornment>
                      }
                    />
                    {tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== null &&
                      tableDetails?.[currentClaim - 1]?.changedValues?.UnitPrice !== undefined
                      ? (
                        <img
                          src={ResetChanges}
                          alt="Reset changes"
                          className="reset-btn"
                          onClick={() =>
                            handleResetCellChanges(currentClaim - 1, 'UnitPrice')
                          }
                        />
                      ) : null}
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t('tax')}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{
                      backgroundColor:
                        editingField === 'Tax' ||
                          (tableDetails?.[currentClaim - 1]?.changedValues?.Tax !== null &&
                            tableDetails?.[currentClaim - 1]?.changedValues?.Tax !== undefined)
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('Tax')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'Tax'
                            ? 'var(--b-color-additional-1)'
                            : tableDetails?.[currentClaim - 1]?.changedValues
                              ?.Tax !== null &&
                              tableDetails?.[currentClaim - 1]?.changedValues
                                ?.Tax !== undefined
                              ? 'var(--b-color-bg-main-2)'
                              : '#312f2f',
                        backgroundColor:
                          editingField === 'Tax'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      type="text"
                      align="center"
                      placeholder="-"
                      onChange={(e) => handleUpdate(currentClaim - 1, 'Tax', e)}
                      value={
                        numToStringConverter(tableDetails?.[currentClaim - 1]?.changedValues?.Tax ??
                          tableDetails?.[currentClaim - 1]?.Tax)
                      }
                      endAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                    />
                    {tableDetails?.[currentClaim - 1]?.changedValues?.Tax !== null &&
                      tableDetails?.[currentClaim - 1]?.changedValues?.Tax !== undefined
                      ? (
                        <img
                          src={ResetChanges}
                          alt="Reset changes"
                          className="reset-btn"
                          onClick={() =>
                            handleResetCellChanges(currentClaim - 1, 'Tax')
                          }
                        />
                      ) : null}
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t('amount')}</div>
                  <div
                    className="policy-table-mobile__right CellAmountRed"
                    style={{
                      backgroundColor:
                        editingField === 'Amount' ||
                          (tableDetails?.[currentClaim - 1]?.changedValues?.Amount !== null &&
                            tableDetails?.[currentClaim - 1]?.changedValues?.Amount !== undefined)
                          ? 'var(--b-color-additional-1)'
                          : '#FCE7D2',
                    }}
                    onClick={() => setEditingField('Amount')}
                    onMouseOut={() => setEditingField(null)}
                  >
                    <Input
                      className="input-editable-invoice"
                      sx={{
                        color:
                          editingField === 'Amount'
                            ? 'var(--b-color-additional-1)'
                            : 'var(--b-color-main-1)',
                        backgroundColor:
                          editingField === 'Amount'
                            ? 'var(--b-color-bg-main-2)'
                            : 'unset',
                      }}
                      disableUnderline
                      type="text"
                      align="center"
                      placeholder="-"
                      onChange={(e) =>
                        handleUpdate(currentClaim - 1, 'Amount', e)
                      }
                      value={
                        numToStringConverter(tableDetails?.[currentClaim - 1]?.changedValues?.Amount ??
                          tableDetails?.[currentClaim - 1]?.Amount)
                      }
                      endAdornment={
                        <InputAdornment position="start">
                          {tableDetails?.[currentClaim - 1]?.Currency == '$'
                            ? '$'
                            : '€'}
                        </InputAdornment>
                      }
                    />
                    {tableDetails?.[currentClaim - 1]?.changedValues?.Amount !== null &&
                      tableDetails?.[currentClaim - 1]?.changedValues?.Amount !== undefined
                      ? (
                        <img
                          src={ResetChanges}
                          alt="Reset changes"
                          className="reset-btn"
                          onClick={() =>
                            handleResetCellChanges(currentClaim - 1, 'Amount')
                          }
                        />
                      ) : null}
                  </div>
                </div>

                {tableDetails?.[currentClaim - 1]?.IsNewLine
                  ? (
                    <div
                      className="policy-table-mobile__button"
                      style={{
                        color: 'var(--b-color-bg-main-2)',
                        backgroundColor: 'var(--b-color-additional-1)',
                      }}
                      onClick={() => handleDeleteLine(currentClaim - 1)}
                    >
                      <img src={DeleteLine} alt="Delete line" />
                      <div>{t('deleteLine')}</div>
                    </div>
                  ) : (
                    <div
                      className="policy-table-mobile__button"
                      style={{
                        color: 'var(--b-color-bg-main-2)',
                        backgroundColor: 'var(--b-color-additional-1)',
                      }}
                      onClick={() => handleAddNew()}
                    >
                      <img src={AddLine} alt="Add line" />
                      <div>{t('addLine')}</div>
                    </div>
                  )}

                {tableDetails?.length > 1 && (
                  <div className="policy-table-mobile__pagination">
                    <Pagination
                      className="reset-pagination"
                      count={tableDetails?.length}
                      page={currentClaim}
                      siblingCount={1}
                      boundaryCount={1}
                      onChange={handlePage}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowLeftRed,
                            next: ArrowRightRed,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <TableRestyle
              initialTableDetails={initialTableDetails}
              tableDetails={tableDetails}
              handleUpdate={handleUpdate}
              editValues={true}
              selectedTableDetails={selectedTableDetails}
              resultTableDetails={resultTableDetails}
              handleNew={handleNew}
              updatingIndex={updatingIndex}
              setUpdatingIndex={setUpdatingIndex}
              resetRowChanges={resetRowChanges}
              deleteNewLine={deleteNewLine}
            />
          )}

          <div className="invoice-modal-buttons">
            <div
              className={`button_red_invoice ${isMobileOnly ? 'mobile' : ''} ${isTouched ? '' : 'disabled'}`}
              onClick={isTouched ? handleResetChanges : () => { }}
            >
              {t('resetChanges')}
            </div>
            <div
              className={`button_red_invoice ${isMobileOnly ? 'mobile' : ''} ${isTouched && !hasEmptyInputs ? '' : 'disabled'}`}
              onClick={isTouched && !hasEmptyInputs ? handleClose : () => { }}
            >
              {t('saveChanges')}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalEditableInvoice;
