export const chartsExcel = {
  DOG: {
    'OP Basic': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: '2.000€',
              additional: '(bei Unfällen 2.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'day_5',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'OP Comfort': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '2.000€',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'OP Full': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'day_14',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'Health Basic': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: '2.000€',
              additional: '(bei Unfällen 2.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: null,
              content: '1.000€',
              additional: '(bei Unfällen 1.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        not_available: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nickhautdrüsenvorfall/Cherry Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epiliepsie/Krampfanfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandrisse',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige und bösartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title:
              'Erkrankungen, die vor Versicherungsbeginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'Health Comfort': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '2.000€',
              additional: '(bei Unfällen 15.000€)',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: '15.000€',
              additional: '(bei Unfällen 15.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '600€',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Prothesen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_KR',
          },
        ],
      },
    },
    'Health Full': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: null,
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'day_14',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Prothesen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_KR',
          },
        ],
      },
    },
  },

  CAT: {
    'OP Basic': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: '2.000€',
              additional: '(bei Unfällen 2.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'day_5',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schildrüsenerkrankungen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'OP Comfort': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '2.000€',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schildrüsenerkrankungen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'OP Full': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'day_14',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schildrüsenerkrankungen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'Health Basic': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: null,
              content: '2.000€',
              additional: '(bei Unfällen 2.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '1.000€',
              additional: '(bei Unfällen 1.000€)',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: '1.000€',
              additional: '(bei Unfällen 1.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        not_available: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nickhautdrüsenvorfall/Cherry Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epiliepsie/Krampfanfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandrisse',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige und bösartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title:
              'Erkrankungen, die vor Versicherungsbeginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_OP',
          },
        ],
      },
    },
    'Health Comfort': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '2.000€',
              additional: '(bei Unfällen 15.000€)',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: '15.000€',
              additional: '(bei Unfällen 15.000€)',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: '1. Vers. Jahr',
              content: '600€',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'month_1',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schildrüsenerkrankungen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Prothesen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_KR',
          },
        ],
      },
    },
    'Health Full': {
      tarif_info: [
        {
          itemName: 'annMaxOPs',
          children: [
            {
              title: '1. Vers. Jahr',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'annMaxTreat',
          children: [
            {
              title: '1. Vers. Jahr',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
            {
              title: 'ab 2. Vers. Jahr:',
              content: 'unlim',
              additional: 'bei Unfällen unbegrenzt',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'waitingStart',
          children: [
            {
              title: 'day_14',
              content: null,
              tooltip: null,
            },
            {
              title: 'bei Unfall:',
              content: 'no_waiting_time',
              tooltip: null,
            },
          ],
        },
        {
          itemName: 'pandaBudget',
          children: [
            {
              title: '70€ je Vers. Jahr',
              content: null,
              tooltip: 'tooltip3',
              scrollable: true,
            },
          ],
        },
      ],
      additional_info: {
        available3: [
          {
            title: 'Pankreatitis',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schildrüsenerkrankungen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Diabetes',
            children: null,
            tooltip: null,
          },
          {
            title: 'Asthma',
            children: null,
            tooltip: null,
          },
          {
            title: 'Onkologische Krankheiten (gutartige Tumore)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Niereninsuffizienz',
            children: null,
            tooltip: null,
          },
        ],
        available12: [
          {
            title: 'Hüftgelenksdysplasie (HD)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Ellenbogendysplasie (ED)',
            children: [
              {
                content: 'Osteochondrosis dissecans (OCD)',
                tooltip: null,
              },
              {
                content: 'Frakturierter Processus Coronoideus (FPC)',
                tooltip: null,
              },
              {
                content: 'Isolierter Processus Anconaeus (IPA)',
                tooltip: null,
              },
              {
                content: 'Radius Curvus (Short-Ulna-Syndrom)',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
          {
            title: 'Erworbene Herzerkrankungen und Allergien',
            children: null,
            tooltip: null,
          },
          {
            title: 'Schilddrüsenerkrankungen bei Hunden',
            children: null,
            tooltip: null,
          },
          {
            title: 'Prothesen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Cherry-Eye',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kreuzbandriss',
            children: null,
            tooltip: null,
          },
          {
            title: 'Arthrosen',
            children: null,
            tooltip: null,
          },
          {
            title: 'Patellaluxation',
            children: null,
            tooltip: null,
          },
          {
            title: 'Bandscheibenvorfälle',
            children: null,
            tooltip: null,
          },
          {
            title: 'Epilepsie',
            children: null,
            tooltip: null,
          },
          {
            title: 'Spinale Muskelatrophie (SMA)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Mucopolysaccharidose (MPS VI)',
            children: null,
            tooltip: null,
          },
          {
            title: 'FORL/CORL',
            children: null,
            tooltip: null,
          },
          {
            title: 'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
            children: null,
            tooltip: null,
          },
        ],
        available18: [
          {
            title: 'Onkologische Krankheiten (bösartige Tumore)',
            children: [
              {
                content:
                  'Ausnahme: Für die Behandlung gutartiger Tumore besteht Versicherungsschutz nach Ablauf der besonderen Wartezeit von 3 Monaten',
                tooltip: null,
              },
            ],
            tooltip: null,
          },
        ],
        not_available: [
          {
            title:
              'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
            children: null,
            tooltip: null,
          },
          {
            title: 'Kieferanomalien (z.B. Persistierende Milchzähne)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
            children: null,
            tooltip: null,
          },
          {
            title: 'Nicht medizinisch notwendige Kastration oder Sterilisation',
            children: null,
            tooltip: 'tooltip1_KR',
          },
        ],
      },
    },
  },
};
