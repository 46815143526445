import React, { useEffect, useState } from 'react';
import {
  getAdminTempToken,
  getToken,
  getUser,
  setAdminTempToken,
  setToken,
} from '../../config/token';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsAdminLoggedAsUser,
  updateIsAdminViewMode,
  updateIsUserModalSelect,
  updateLanguage,
  updateMode,
  // updatePolicyReactivateModalSelect,
} from '../../config/actions';
import {
  HEADER_LINKS,
  LANGUAGES,
  USER_MODE,
} from '../../constants/ApplicationConstants';
import FlagEN from '../../assets/images/united-kingdom.png';
import FlagGE from '../../assets/images/germany.png';
import MouthRed from '../../assets/header/mouth_red.svg';
import BlinkRed from '../../assets/header/Animation_rot_125px_menüleiste.gif';
import ArrowUpFat from '../../assets/iconsSmall/ep_arrow-up.svg';
import Phone from '../../assets/iconsSmall/phone_red_restyle.svg';
import Email from '../../assets/iconsSmall/email_red_restyle.svg';
import Burger from '../../assets/header/burger_red.svg';
import Cross from '../../assets/header/cross_red.svg';
import CatDog from '../../assets/Insurances/cat_dog.svg';
import { MenuItem, ListItemIcon } from '@mui/material';
import ModalFormEmail from '../../features/claims/modals/ModalFormEmail';
import { StyledMenu, StyledContactMenu } from './style/customStyle';
import ModalPetSelect from '../../features/claims/modals/ModalPetSelect';
import ErrorModal from './errorModal';
import { setPets, updateSelectedPetInfo } from '../../config/actions';
import Loading from '../../components/common/Loading';
import ModalWhatToDo from '../../features/claims/modals/ModalWhatToDo';
import useStickyHeader from '../../hooks/useStickyHeader';
import ModalUserSelect from '../../features/admin/modals/ModalUserSelect';
import { isMobile } from 'react-device-detect';
import { useGetPets } from '../../hooks/useGetPets';

export default function Header() {
  const { token } = getToken() ? JSON.parse(getToken()) : {};
  const { t, i18n } = useTranslation();
  const path = window.location.pathname;
  const router = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const getPets = useGetPets();

  const headerRef = useStickyHeader();

  const mode = useSelector((s) => s.form.mode);
  const pets = useSelector((s) => s.flow.pets);
  const isAdminLoggedAsUser = useSelector((s) => s.admin.isAdminLoggedAsUser);
  const isAdminViewMove = useSelector((s) => s.admin.isAdminViewMove);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const wtdOpenFlag = useSelector((s) => s.flow.wtdOpenFlag);
  const [user] = useState(token ? getUser() : null);
  const [menus, setMenus] = useState(HEADER_LINKS);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [mobileContactsOpen, setMobileContactsOpen] = useState(false);
  const [mobileReportOpen, setMobileReportOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorContact, setAnchorContact] = useState(null);
  const [openModalWhatToDo, setOpenModalWhatToDo] = useState(false);
  const [openModalFormEmail, setOpenModalFormEmail] = useState(false);
  const [openModalPetSelect, setOpenModalPetSelect] = useState(false);
  const [error, setError] = useState(false);
  const [petId, setPetId] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContactSelection = (event) => {
    setAnchorContact(event.currentTarget);
  };

  const handleContactClose = () => {
    setAnchorContact(null);
  };

  const handlerOpenMenu = () => {
    setToggleMenu(true);
    if (toggleUserMenu) {
      setToggleUserMenu(false);
    }
  };

  const handlerCloseMenu = () => {
    setToggleMenu(false);
    setToggleUserMenu(false);
    setMobileContactsOpen(false);
    setMobileReportOpen(false);
  };

  const fetchData = async () => {
    try {
      const { data } = await getPets();
      dispatch(setPets(data));
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  useEffect(() => {
    if (token && !isAdmin && pets?.length === 0 && path !== '/') {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('isAdmin') === 'true') {
      dispatch(updateMode(USER_MODE.ADMIN));
    }
  }, []);

  const onResize = () => {
    if (window.innerWidth >= 1200) setToggleMenu(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (!petId && currentPetInfo.policyId) {
      setPetId(currentPetInfo.policyId);
    }
    if (wtdOpenFlag) setOpenModalWhatToDo(wtdOpenFlag);
  }, [wtdOpenFlag]);

  useEffect(() => {
    setMenus(HEADER_LINKS.filter((i) => i.mode.includes(mode)));
  }, [mode]);

  useEffect(() => {
    if (pets) {
      if (pathname === `/pet/${id}` && pets.length > 0) {
        setPetId(id);
        let currentPet = pets.find((el) => el.policy.id == id);
        if (currentPet?.attributes)
          dispatch(
            updateSelectedPetInfo({
              ...currentPet.attributes,
              type: currentPet.attributes.name,
              policyId: currentPet.policy.id,
            })
          );
      }
      if (pets.length === 1) {
        setPetId(pets[0]?.policy?.id);
        if (pets[0]?.attributes)
          dispatch(
            updateSelectedPetInfo({
              ...pets[0].attributes,
              type: pets[0].attributes.name,
              policyId: pets[0].policy.id,
            })
          );
      }
    }
  }, [pets]);

  const onChangeLang = (lang) => {
    dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
  };

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const logOutAsUser = () => {
    const prevAdminToken = getAdminTempToken()
      ? JSON.parse(getAdminTempToken())
      : undefined;

    setAdminTempToken(JSON.stringify({ token: null }));
    setToken(JSON.stringify({ token: prevAdminToken.token }));
    dispatch(updateIsAdminLoggedAsUser(false));
    dispatch(updateMode(USER_MODE.ADMIN));
    dispatch(updateIsAdminViewMode(false));

    setTimeout(() => {
      router('/management');
      window.location.reload();
    }, 100);
  };

  // const openModalPolicyReactivate = () => {
  //   dispatch(updatePolicyReactivateModalSelect(true));
  // };

  const openModalAdminUserSelect = () => {
    dispatch(updateIsUserModalSelect(true));
  };

  const isAdmin = mode === USER_MODE.ADMIN;

  const reportDamage = mode === USER_MODE.CLIENT && (
    <button
      className="button_red_report"
      onClick={() => {
        pathname === `/pet/${id}`
          ? setOpenModalWhatToDo(true)
          : pets.length === 1
            ? setOpenModalWhatToDo(true)
            : setOpenModalPetSelect(true);
      }}
    >
      {t('reportDamage')}
    </button>
  );

  const LanguageContainer =
    i18n.language === LANGUAGES.EN ? (
      <button
        onClick={() => onChangeLang(LANGUAGES.DE)}
        className="navbar__flag"
      >
        <img src={FlagEN} alt="lang" />
      </button>
    ) : (
      <button
        onClick={() => onChangeLang(LANGUAGES.EN)}
        className="navbar__flag"
      >
        <img src={FlagGE} alt="lang" />
      </button>
    );

  const renderPets = () => {
    const rows = [];
    const numPets = pets?.length;

    for (let i = 0; i < numPets; i += 3) {
      const rowPets = pets.slice(i, i + 3);
      const row = (
        <div key={`row-${i}`} className="pet_container__row-menu">
          {rowPets.map((pet) => (
            <div
              key={pet.id}
              className="pet_container__item"
              onClick={() => {
                setPetId(pet?.policy?.id);
                if (pet?.attributes)
                  dispatch(
                    updateSelectedPetInfo({
                      ...pet.attributes,
                      type: pet.attributes.name,
                      policyId: pet.policy.id,
                    })
                  );
                handlerCloseMenu();
                setOpenModalWhatToDo(true);
              }}
            >
              <div className="pet_container__wrapper-modal">
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div className="pet_container__name">
                {pet?.attributes?.nameOfPet}
                {pets.filter(
                  (el) => el.attributes.nameOfPet === pet.attributes.nameOfPet
                ).length > 1 && <div>{pet?.policy.policyNo}</div>}
              </div>
            </div>
          ))}
        </div>
      );
      rows.push(row);
    }
    return pets.length > 0 ? rows : <Loading noText fast />;
  };

  return (
    <>
      <div className="main-header" ref={headerRef}>
        <div className="container-header relative FastShow">
          <div className="header-logo-wrapper">
            <button
              className="navbar-brand btn-reset"
              data-cy="logo"
              onClick={() => {
                isAdmin && !isAdminLoggedAsUser
                  ? router('/management')
                  : router('/');
              }}
            >
              <img src={BlinkRed} alt="BlinkRed" className="header__logo" />
            </button>
          </div>

          {/* Desktop */}

          {token && user && (
            <div className="header__row">
              <div className="header__menu_buttons">
                {menus.map((i) => (
                  <NavLink key={i.href} className="nav-item" to={i.href}>
                    <div className="header__logo-mouth">
                      <span
                        aria-current="page"
                        className={` ${i.href === path && 'header__active-link'
                          }`}
                      >
                        {i.title === 'myDamages' && isAdmin
                          ? t('allDamages')
                          : t(i.title)}
                      </span>
                      {i.href === path && <img src={MouthRed} alt="MouthRed" />}
                    </div>
                  </NavLink>
                ))}

                {isAdmin && !isAdminLoggedAsUser && (
                  <>
                    {/* Temporary hidden option "Reactivate policy" */}

                    {/* <div className="header__logo-mouth">
                      <span
                        aria-current="page"
                        style={{ cursor: "pointer" }}
                        onClick={openModalPolicyReactivate}
                      >
                        {t("policyReactivate")}
                      </span>
                    </div> */}
                    <div className="header__logo-mouth">
                      <span
                        aria-current="page"
                        style={{ cursor: 'pointer' }}
                        onClick={openModalAdminUserSelect}
                      >
                        {t('LoginAsUser')}
                      </span>
                    </div>
                  </>
                )}

                {isAdminViewMove && isAdminLoggedAsUser && (
                  <div className="header__logo-mouth">
                    <span
                      aria-current="page"
                      style={{ cursor: 'pointer' }}
                      onClick={logOutAsUser}
                    >
                      {t('Logout as user')}
                    </span>
                  </div>
                )}
              </div>

              <div className="header__action_buttons">
                {pets?.length > 0 && reportDamage}

                {!isAdmin && (
                  <div
                    className="button_red_contact"
                    onClick={handleContactSelection}
                  >
                    <div></div>
                    <span>{t('contact')}</span>
                    <img
                      src={ArrowUpFat}
                      width={24}
                      style={{
                        transform: !anchorContact ? 'rotate(180deg)' : '',
                        transition: '0.3s',
                      }}
                    />
                  </div>
                )}

                {/* Contacts */}

                <StyledContactMenu
                  id="menu-contactbar"
                  anchorEl={anchorContact}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorContact)}
                  onClose={handleContactClose}
                >
                  <MenuItem onClick={() => {
                    setOpenModalFormEmail(true);
                    handleContactClose();
                  }}>
                    <ListItemIcon>
                      <img src={Email} />
                    </ListItemIcon>
                    {t('sendEmail')}
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontVariantNumeric: 'lining-nums',
                      cursor: 'unset',
                    }}
                  >
                    <ListItemIcon>
                      <img src={Phone} />
                    </ListItemIcon>
                    {isMobile ? (
                      <a href="tel:+49 851 986 939 90" className="phone-number">
                        +49 851 986 939 90
                      </a>
                    ) : (
                      '+49 851 986 939 90'
                    )}
                  </MenuItem>
                </StyledContactMenu>
              </div>

              {/* Language / Logout menu */}

              <div className="header__logout">
                {LanguageContainer}
                <div
                  className="header__logout__user"
                  onClick={
                    window.innerWidth > 920
                      ? handleMenu
                      : () => {
                        if (toggleMenu) {
                          setToggleMenu(false);
                        }
                        setToggleUserMenu(!toggleUserMenu);
                      }
                  }
                >
                  {user.email ? user.email[0].toUpperCase() : ''}
                </div>
                <StyledMenu
                  id="menu-appbar"
                  sx={{ fontVariantNumeric: 'lining-nums' }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      router('/');
                      handleClose();
                    }}
                  >
                    {user.email}
                  </MenuItem>
                  <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
                </StyledMenu>
                <img
                  src={toggleMenu ? Cross : Burger}
                  alt="Burger-menu"
                  className="burger_menu"
                  onClick={toggleMenu ? handlerCloseMenu : handlerOpenMenu}
                />
              </div>
            </div>
          )}

          {/* Burger-menu for mobile */}

          {toggleMenu && (
            <div className="header-spacer FastShow">
              {menus.map((i) => (
                <NavLink
                  key={i.href}
                  className="header__nav-link menu-item"
                  to={i.href}
                >
                  <div className="header__logo-mouth">
                    <div
                      aria-current="page"
                      className={`header__nav-link__item ${i.href === path && 'header__active-link'
                        }`}
                    >
                      <span>
                        {i.title === 'myDamages' && isAdmin
                          ? t('allDamages')
                          : t(i.title)}
                      </span>
                      {i.href === path && <img src={MouthRed} alt="MouthRed" />}
                    </div>
                  </div>
                </NavLink>
              ))}

              {isAdmin && !isAdminLoggedAsUser && (
                <>
                  {/* Temporary hidden option "Reactivate policy" */}

                  {/* <div className="header__logo-mouth">
                    <span
                      aria-current="page"
                      style={{ cursor: "pointer" }}
                      onClick={openModalPolicyReactivate}
                    >
                      {t("policyReactivate")}
                    </span>
                  </div> */}
                  <div className="header__logo-mouth">
                    <span
                      aria-current="page"
                      style={{ cursor: 'pointer' }}
                      onClick={openModalAdminUserSelect}
                    >
                      {t('LoginAsUser')}
                    </span>
                  </div>
                </>
              )}

              {isAdminViewMove && isAdminLoggedAsUser && (
                <div className="header__logo-mouth">
                  <span
                    aria-current="page"
                    style={{ cursor: 'pointer' }}
                    onClick={logOutAsUser}
                  >
                    {t('Logout as user')}
                  </span>
                </div>
              )}

              {/* Contacts for mobile */}

              <div className="buttons_menu_wrapper menu-item">
                <div
                  className="button_dark-red_contact_mobile"
                  onClick={() => setMobileContactsOpen(!mobileContactsOpen)}
                >
                  <div></div>
                  <span>{t('contact')}</span>
                  <img
                    src={ArrowUpFat}
                    width={24}
                    style={{
                      transform: !mobileContactsOpen ? 'rotate(180deg)' : '',
                      transition: '0.3s',
                    }}
                  />
                </div>
              </div>
              {mobileContactsOpen && (
                <div className="header__contact-box FastShow">
                  <div
                    className="header__contact-item pointer"
                    onClick={() => setOpenModalFormEmail(true)}
                  >
                    <img src={Email} />
                    {t('sendEmail')}
                  </div>
                  <div className="header__contact-item pointer">
                    <img src={Phone} />
                    {isMobile ? (
                      <a href="tel:+49 851 986 939 90" className="phone-number">
                        +49 851 986 939 90
                      </a>
                    ) : (
                      '+49 851 986 939 90'
                    )}
                  </div>
                </div>
              )}

              {/* Report types + pet select for mobile*/}

              <div className="buttons_menu_wrapper menu-item">
                <div
                  className="button_red_contact_mobile"
                  onClick={() => {
                    pathname === `/pet/${id}`
                      ? setOpenModalWhatToDo(true)
                      : pets.length === 1
                        ? setOpenModalWhatToDo(true)
                        : setMobileReportOpen(!mobileReportOpen);
                  }}
                >
                  {pathname === `/pet/${id}` || pets.length === 1 ? (
                    <span style={{ width: '100%', textAlign: 'center' }}>
                      {t('submitInvoice')}
                    </span>
                  ) : (
                    <>
                      <div></div>
                      <span>{t('submitInvoice')}</span>
                      <img
                        src={ArrowUpFat}
                        width={24}
                        style={{
                          transform: !mobileReportOpen ? 'rotate(180deg)' : '',
                          transition: '0.3s',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              {mobileReportOpen && (
                <div className="header__pet-box menu-item FastShow">
                  <div className="header__pet-box__title">
                    {t('whichPetContinue')}
                  </div>
                  {renderPets()}
                </div>
              )}

              <div className="header-blur" onClick={handlerCloseMenu}></div>
            </div>
          )}

          {/* User menu */}

          {toggleUserMenu && (
            <div className="header-spacer FastShow">
              <div
                className="header__logo-mouth pointer"
                onClick={() => router('/')}
              >
                {user.email}
              </div>
              <div className="header__logo-mouth pointer" onClick={onLogout}>
                {t('Logout')}
              </div>
              <div className="header-blur" onClick={handlerCloseMenu}></div>
            </div>
          )}
          {!token && <div>{LanguageContainer}</div>}
        </div>
      </div>

      {openModalPetSelect && (
        <ModalPetSelect
          openModalPetSelect={openModalPetSelect}
          setOpenModalPetSelect={setOpenModalPetSelect}
          setOpenModalWhatToDo={setOpenModalWhatToDo}
          setPetId={setPetId}
        />
      )}
      {openModalWhatToDo && (
        <ModalWhatToDo
          setOpenModalWhatToDo={setOpenModalWhatToDo}
          setOpenModalPetSelect={setOpenModalPetSelect}
          petId={petId}
        />
      )}
      {openModalFormEmail && (
        <ModalFormEmail
          openModalFormEmail={openModalFormEmail}
          setOpenModalFormEmail={setOpenModalFormEmail}
        />
      )}
      {error && (
        <ErrorModal openModal={error} setErrorModal={() => setError(!error)} />
      )}

      {isAdmin && <ModalUserSelect />}
      {/* {isAdmin && <ModalPolicyReactivate />} */}
    </>
  );
}
